// react imports
import React, {  useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import 'react-calendar/dist/Calendar.css';
import moment from 'moment'
// CUSTOM COMPONENTS
import ProgramCard from "../components/cards/ProgramCard"

import { Kovetkezo_programok, Geszti_koncert_leiras, Beck_Grecsot_leiras } from '../data/TartalmiElemek'


import { MDBContainer
          } from 'mdb-react-ui-kit';


const ProgramDetailPage = (props) => {

  const location = useLocation();


  moment.updateLocale('hu', {
    months : [
        "Január", "Február", "Március", "Április", "Május", "Június", "Július",
        "Augusztus", "Szeptember", "Október", "November", "December"
    ]
  }); 

  // STATES
  const [fullItemList, setFullItemList] = useState([]);
  const [program, setProgram] = useState();
  const [divWidth, setDivWidth] = useState();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
    setProgram(location.state.program)
    setFullItemList(Kovetkezo_programok);
  }, [location.state.program]);

  useEffect(() => {
    setTimeout(() => setDivWidth(document.getElementById("program-background-image")?.offsetWidth), 100);
    
    
  }, []);

  return (
    <>
    {program && (
 <section className="programdetail-section" id ="programdetail-section" style={{minHeight: "calc(100vh - 617px"}}>
  <MDBContainer style={{maxWidth: "1920px"}} className='p-0'>
    <div className="programdetail-header">
      <div className="program-background-image" id="program-background-image">
          <img src={program.image_detail} alt="program-background"/>
          <div className="datum-div">
            <span>{program.datum}</span>
          </div>
      </div>
      <div className="program-detail">
        <div className="program-detail-up">
          <div className="propgram-title">
            <span>{program.title}</span>
          </div>
          <p className="title">Helyszín:</p>
          <span className="text">{program.helyszin}</span>
          <p className="title">Időpont:</p>
          <span className="text">{moment(program.datum).locale('hu').format('HH:mm')}</span>
          <p className="title">Esemény kategória:</p>
          <span className="text">{program.esemenykategoria}</span>
        
        <div className="program-detail-down">
          <p>{"Jegyek: " + program.minjegyar + " Forinttól"}</p>
          <button type="button" className="muster-button kiemelt"><span>Jegyvásárlás</span></button>
        </div>
        </div>
      </div>
    </div>
    {divWidth && (
      <div className="programdetail-body">
        <div className="programdetail-description" style={{width: divWidth}}>
          <div style={{maxWidth: "710px"}}>
            {program.description === "geszti" ? Geszti_koncert_leiras : program.description === "beck-grecso" ? Beck_Grecsot_leiras : ""}
          </div>
        </div>
        <div className="kozelgo-programok"  style={{width: 1920 - divWidth}}>
          <div className="program-title">
            <span>Közelgő programok</span>
          </div>
          {fullItemList.filter(k => k.datum > program.datum).filter((k,index) => index < 2).map((k,n) => {return (
            <div key={n}>
              <ProgramCard msg={k}/>
            </div>
        )})}
            
        </div>
      </div>
    )}
   
  </MDBContainer>
 
 {/*<MDBContainer style={{maxWidth: "1284px"}} className='p-0'>
       <div>
         <div className="program-title">
           <span>Állandó programok</span>
         </div>
         <MDBRow className="m-0 programok-row">
           {fullItemList.map((k,n) => {return (
             <MDBCol md="4" className="programok-col" key={n}>
               <ProgramCard msg={k} allandoprogramok={true}/>
             </MDBCol>
           )})}
       
         </MDBRow>
       </div>
           </MDBContainer>*/}
</section>
    )}
   
    </>
  );
};

export default ProgramDetailPage;
