// react imports
import React from "react";
import { useNavigate } from "react-router-dom";

const HirekFooldalCard  = (props) => {
  
  const navigate = useNavigate();


  return (
    <div className="hirekfooldal-card-div" onClick={() => navigate('/hirekdetailpage', {
                                                                                    state: {
                                                                                      hirek: props.msg,
                                                                                    }
                                                                                  })}>
        <div className="image-div">
            <img src={props.msg.image} alt={props.msg.id}/>
        </div>
        <div className="hirefooldal-text">
          <div className="title-div">
              <span>{props.msg.title}</span>
          </div>
          <div className="text-div">
              <span>{props.msg.datum}</span>
          </div>
        </div>
        
    </div>
  );
};

export default HirekFooldalCard ;
