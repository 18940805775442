// react imports
import React, { useContext, useState, useEffect } from "react";
import ForgotpasswordForm from "../components/form-components/ForgotpasswordForm";
import AppContext from '../AppContext';
import { MDBContainer
          } from 'mdb-react-ui-kit';


const ForgotpasswordPage = (props) => {
  const context = useContext(AppContext);

  useEffect(() => {
    setTimeout(() => window.scrollTo({
      top: 0,
      behavior: "smooth"
    }), 100);
    
  }, []);


  return (
    <>
    <section className="hirlevelpage-section" id ="hirlevelpage-section">
      <MDBContainer style={{maxWidth: "1284px"}} className='p-0'>
        <div className="hirlevel-title">
          <span>Új jelszó igénylése</span>
        </div>
      </MDBContainer>
    </section>
    <section className="hirlevelform-section" id ="hirlevelform-section" style={{paddingBottom: "85px"}}>
       <MDBContainer style={{maxWidth: "1284px"}} className='p-0'>
        <div className="hirlevelform-div">
          <div className="mb-3">
          <span className="title-text ">Adja meg új jelszavát!</span>
          </div>
          <ForgotpasswordForm context={context}/>
        </div>
      </MDBContainer>
    </section>
    </>
  );
};

export default ForgotpasswordPage;
