// react imports
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import moment from 'moment'
// CUSTOM COMPONENTS


import { MDBContainer
          } from 'mdb-react-ui-kit';


const HirekDetailPage = (props) => {

  const location = useLocation();
  // STATES
  const [hirek, setHirek] = useState([]);
  
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
    setHirek(location.state.hirek)
  }, [location.state.hirek]);

 


  return (
    <section className="hirekdetailpage-section" id="hirekdetailpage-section">
      {hirek && (
         <MDBContainer style={{maxWidth: "1260px"}} className='p-0'>
            <div className="boritokep-div">
              <img src={hirek.image_big} alt="hirek-borito"/>
            </div>
            <div className="hirek-detail">
              <div className="left-side">
                <div className="first-clolumn">
                  <span className="datum">{moment(hirek.datum).locale('hu').format('YYYY.MM.DD')}</span>
                  <span className="title">Kategória</span>
                  <span className="description">Hírek, beszámolók</span>
                  <div className="border-right"/>
                </div>
              </div>
              <div className="right-side">
                <div className="second-column">
                  <span className="title">{hirek.title}</span>
                  <span className="description">{hirek.description}</span>
                  <span className="tovabbi-informaciok">További részletek és jelentkezés <a href={hirek.link} target="_blank" rel="noopener noreferrer">ITT</a>.</span>
                </div>
              </div>
            </div>
         </MDBContainer>
      )}
    </section>
  );
};

export default HirekDetailPage;
