import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import AppContextProvider from './AppContextProvider';
import { GoogleOAuthProvider } from '@react-oauth/google';
import Layout from './components/layout/Layout';
import HomePage from './pages/HomePage';
import HirekListPage from './pages/HirekListPage';
import HirekDetailPage from './pages/HirekDetailPage';
import ProgramokListPage from './pages/ProgramokListPage';
import AllandoProgramokPage from './pages/AllandoProgramokPage';
import ArchivProgramokPage from './pages/ArchivProgramokPage';
import ProgramDetailPage from './pages/ProgramDetailPage';
import AVertesAgorajaPage from './pages/AVertesAgorajaPage';
import Kapcsolatpage from './pages/Kapcsolatpage';
import GaleriaKategoriaPage from './pages/GaleriaKategoriaPage';
import GaleriaPage from './pages/GaleriaPage';
import HirlevelFeliratkozasPage from './pages/HirlevelFeliratkozasPage';
import BelepesPage from './pages/BelepesPage';
import RegisztracioPage from './pages/RegisztracioPage';
import JelszoigenyloPage from './pages/JelszoigenyloPage';
import ForgotpasswordPage from './pages/ForgotpasswordPage';
import { GoogleOAuthId } from './data/loginkeys';


function App() {
  return (
    <GoogleOAuthProvider clientId={GoogleOAuthId.clientId}>
    <AppContextProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout/>}>
            <Route path="" element={<HomePage />} />
            <Route path="/home" element={<HomePage />} />
            <Route path="/kovetkezoprogramok" element={<ProgramokListPage />} />
            <Route path="/allandoprogramok" element={<AllandoProgramokPage />} />
            <Route path="/archivum" element={<ArchivProgramokPage />} />
            <Route path="/programdetailpage" element={<ProgramDetailPage />} />
            <Route path="/hirek" element={<HirekListPage />} />
            <Route path="/hirekdetailpage" element={<HirekDetailPage />} />
            <Route path="/kapcsolat" element={<Kapcsolatpage />} />
            <Route path="/ava" element={<AVertesAgorajaPage />} />
            <Route path="/palyazatok" element={<HomePage />} />
            <Route path="/kozossegiszolgalat" element={<HomePage />} />
            <Route path="/kortarsgaleria" element={<HomePage />} />
            <Route path="/galeria" element={<GaleriaKategoriaPage />} />
            <Route path="/galeriadetailpage" element={<GaleriaPage />} />
            <Route path="/hirlevelpage" element={<HirlevelFeliratkozasPage />} />
            <Route path="/belepes" element={<BelepesPage />} />
            <Route path="/regisztracio" element={<RegisztracioPage />} />
            <Route path="/ujjelszo" element={<JelszoigenyloPage />} />
            <Route path="/forgotpassword" element={<ForgotpasswordPage />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </AppContextProvider>
    </GoogleOAuthProvider>
  );
}

export default App;
