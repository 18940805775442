import slide_1 from "../assets/media/images/slider fotok/image 1.png"
import slide_2 from "../assets/media/images/slider fotok/image 2.png"
import slide_3 from "../assets/media/images/slider fotok/image 3.png"

import program_1 from "../assets/media/images/program fotok/1.png"
import program_2 from "../assets/media/images/program fotok/2.png"
import program_3 from "../assets/media/images/program fotok/3.png"
import program_4 from "../assets/media/images/program fotok/4.png"
import program_5 from "../assets/media/images/program fotok/5.png"
import program_6 from "../assets/media/images/program fotok/6.png"

import hirek_1 from "../assets/media/images/hirek fotok/Hirek_1.png"
import hirek_1_big from "../assets/media/images/hirek fotok/Állásajánlat_kép.png"
import hirek_2 from "../assets/media/images/hirek fotok/Hirek_2.png"
import hirek_3 from "../assets/media/images/hirek fotok/Hirek_3.png"
import hirek_4 from "../assets/media/images/hirek fotok/Hirek_4.png"
import hirek_5 from "../assets/media/images/hirek fotok/Hirek_5.png"
import hirek_6 from "../assets/media/images/hirek fotok/Hirek_6.png"
import hirek_7 from "../assets/media/images/hirek fotok/Hirek_7.png"
import hirek_8 from "../assets/media/images/hirek fotok/Hirek_8.png"
import hirek_9 from "../assets/media/images/hirek fotok/Hirek_9.png"
import hirek_10 from "../assets/media/images/hirek fotok/Hirek_10.png"
import hirek_11 from "../assets/media/images/hirek fotok/Hirek_11.png"

import allando_1 from "../assets/media/images/program fotok/allando_1.png"
import allando_2 from "../assets/media/images/program fotok/allando_2.png"
import allando_3 from "../assets/media/images/program fotok/allando_3.png"
import allando_4 from "../assets/media/images/program fotok/allando_4.png"

import archiv_1 from "../assets/media/images/program fotok/archiv_1.png"
import archiv_2 from "../assets/media/images/program fotok/archiv_2.png"
import archiv_3 from "../assets/media/images/program fotok/archiv_3.png"
import archiv_4 from "../assets/media/images/program fotok/archiv_4.png"
import archiv_6 from "../assets/media/images/program fotok/archiv_6.png"
import archiv_7 from "../assets/media/images/program fotok/archiv_7.png"

import galeriaborito_1 from "../assets/media/images/galeria fotok/galeriaborito_1.png"
import galeriaborito_2 from "../assets/media/images/galeria fotok/galeriaborito_2.png"
import galeriaborito_3 from "../assets/media/images/galeria fotok/galeriaborito_3.png"
import galeriaborito_4 from "../assets/media/images/galeria fotok/galeriaborito_4.png"
import galeriaborito_5 from "../assets/media/images/galeria fotok/galeriaborito_5.png"
import galeriaborito_6 from "../assets/media/images/galeria fotok/galeriaborito_6.png"
import galeriaborito_7 from "../assets/media/images/galeria fotok/galeriaborito_7.png"
import galeriaborito_8 from "../assets/media/images/galeria fotok/galeriaborito_8.png"

import galeria_1 from "../assets/media/images/galeria fotok/galeriareszlet_1.png"
import galeria_2 from "../assets/media/images/galeria fotok/galeriareszlet_2.png"
import galeria_3 from "../assets/media/images/galeria fotok/galeriareszlet_3.png"
import galeria_4 from "../assets/media/images/galeria fotok/galeriareszlet_4.png"
import galeria_5 from "../assets/media/images/galeria fotok/galeriareszlet_5.png"
import galeria_6 from "../assets/media/images/galeria fotok/galeriareszlet_6.png"
import galeria_7 from "../assets/media/images/galeria fotok/galeriareszlet_7.png"
import galeria_8 from "../assets/media/images/galeria fotok/galeriareszlet_8.png"
import galeria_9 from "../assets/media/images/galeria fotok/galeriareszlet_9.png"
import galeria_10 from "../assets/media/images/galeria fotok/galeriareszlet_10.png"
import galeria_11 from "../assets/media/images/galeria fotok/galeriareszlet_11.png"
import galeria_12 from "../assets/media/images/galeria fotok/galeriareszlet_12.png"
import galeria_13 from "../assets/media/images/galeria fotok/galeriareszlet_13.png"
import galeria_14 from "../assets/media/images/galeria fotok/galeriareszlet_14.png"
import galeria_15 from "../assets/media/images/galeria fotok/galeriareszlet_15.png"
import galeria_16 from "../assets/media/images/galeria fotok/galeriareszlet_16.png"
import galeria_17 from "../assets/media/images/galeria fotok/galeriareszlet_17.png"
import galeria_18 from "../assets/media/images/galeria fotok/galeriareszlet_18.png"

import programfuzet from "../assets/media/images/programfuzet borito/borito_1.png"

export const Geszti_koncert_leiras = 
    <>
        <div className="muvhaz-name">
            <span>A Vértes Agorája bemutatja</span>
        </div>
        <div className="musor-name">
            <span>BEST OF GESZTI koncert</span>
        </div>
        <div className="helyszin-name">
            <span>a Földi Imre Sportcsarnokban</span>
        </div>
        <div className="program-leiras">
            <span>Rapülők, Jazz+Az, Gringo Sztár, Létvágy… Geszti Péter, a sokoldalú előadóművész és csapata sok generációnak tud újat, na meg régit mutatni. Van, hogy Geszti rímbe rángat, olykor igényes jazzel, máskor stadionrepesztő produkciókkal lepi meg közönségét. 2023-ban sem lesz másképp, ráadásul a régi és új rajongók most mindenből a legjobbat kapják.
            A Best of Geszti koncert minden, ami Gesztiben jó, egy új, ütős, szórakoztató produkcióba sűrítve. A Rapülők, a Jazz+Az, a Gringó Sztár és a Létvágy legnépszerűbb dalai, vizuális kényeztetéssel, jó kedvvel, bőséggel.
            Geszti időtlen, mint a jókedv - amire mindenkinek szüksége van. A 2016-ban megjelent Létvágy album bejárta az országot és a határon túli közösségeket, a Rapülőkkel pedig 2017-ben kétszer is megtöltötte a Budapest Arénát, majd vidéki turnéra indult a csapattal.
            Egyszerre retro és egyszerre mai. A kilencvenes évek nagy slágerei mindenkit magukkal ragadnak, de Geszti lendületes színpadi jelenléte és humora feledteti az idők múlását. Ahogy kedvenc mondása szól: „Az idő az egyetlen dolog, ami magától telik, a többi rajtunk múlik.” Rajta pedig nem múlik, hogy nagy buli kerekedjen a koncertjein!
            </span>
        </div>
        <div className="jegyarak-title">
            <span>Jegyárak:</span>
        </div>
        <div className="jegyarak-text">
            <span>Elővételben: 8.500 forint</span>
        </div>
        <div className="jegyarak-text">
            <span>A koncert napján és a helyszínén: 9.900 forint</span>
        </div>
        <div className="jegyarak-text">
            <span>VIP jegy: 28.900 forint</span>
        </div>
        <div className="megjegyzes">
            <span>A VIP jegyet vásárlók számára extra koncertélményt kínálunk: kiemelt helyen korlátlan étel és italfogyasztással, soron kívüli beengedéssel fokozzuk az élményt. A VIP jegyek mellé nyakpántot adunk ajándékba.</span>
        </div>
        <div className="online-vasarlas">
            <span>Belépőjegyek kaphatók A Vértes Agorájában és <a href="/online" target="_blank" rel="noopener noreferrer">online</a>.</span>
        </div>
        <div className="informacio-text">
            <span>A VIP jegyek száma korlátozott!</span>
        </div>
        <div className="informacio-text">
            <span>Utalványt, valamint Cafeteria- és SZÉP Kártyát elfogadunk.</span>
        </div>
        <div className="bovebben">
            <a href="https://avertesagoraja.hu/blog/2017/01/01/utalvany-es-kartyaelfogadas/" target="_blank" rel="noopener noreferrer">Bővebb információ</a>
        </div>

    </>
    

export const Beck_Grecsot_leiras = 
    <>
        <div className="musor-name">
            <span>Hoztunk valamit magunkból</span>
        </div>
        <div className="helyszin-name">
            <span>Beck Zoltán és Grecsó Krisztián zenés irodalmi pódiumestje</span>
        </div>
        <div className="program-leiras">
            <span>Bóbiták, Apokrifek és Balázsolások egy időutazós esten, ahol megidéződnek a mesterek, zene és irodalom találkozik és kiderül, milyen közösek a gyökereik.Az írónál gitár van. A zenész meg felolvas. Grecsó Krisztián és Beck Zoli beszélgetnek, mert barátok, olvasnak, mert írnak, zenélnek, mert vannak dalaik.</span>
        </div>
        <div className="online-vasarlas">
            <span>Belépőjegyek kaphatók A Vértes Agorájában és <a href="/online" target="_blank" rel="noopener noreferrer">online</a>.</span>
        </div>
    </>

export const Kiemelt_program = [
                                    {
                                        id: 1,
                                        image: slide_1,
                                        datum: "2024-01-12 18:00",
                                        title: "Beck@Grecsó",
                                        description: "...hoztunk valamit magunkból"
                                    },
                                    {
                                        id: 2,
                                        image: slide_2,
                                        datum: "2024-01-14 18:00",
                                        title: "BEST OF GESZTI KONCERT",
                                        description: ""
                                    },
                                    {
                                        id: 3,
                                        image: slide_3,
                                        datum: "2024-01-26 16:00",
                                        title: "Mazsola és Tádé",
                                        description: "zenés bábjáték"
                                    }
                                ]

export const Kovetkezo_programok = [
    {
        id: 1,
        image: program_1,
        image_detail: slide_2,
        datum: "2024-01-14 18:00",
        title: "Beszt of Geszti koncert",
        helyszin: "Földi Imre Sportcsarnok",
        esemenykategoria: "Programkínálat, saját szervezésű rendezvény",
        minjegyar: 8500,
        description: "geszti"
    },
    {
        id: 2,
        image: program_2,
        datum: "2024-02-26 16:00",
        title: "Mazsola és Tádé zenés bábjáték",
        helyszin: "A Vértes Agorája"
    },
    {
        id: 3,
        image: program_3,
        datum: "2024-02-16 18:00",
        title: "Huzat Mazzag Izabella",
        helyszin: "A Vértes Agorája",
        minjegyar: "ingyenes",
    },
    {
        id: 4,
        image: program_4,
        datum: "2024-02-21 19:00",
        title: "Miért pont Ázsia? Badár Sándor önálló estje",
        helyszin: "Földi Imre Sportcsarnok"
    },
    {
        id: 5,
        image: program_5,
        image_detail: slide_1,
        datum: "2024-01-12 18:00",
        title: "Beck@Grecsó Hoztunk valamit magunkból",
        helyszin: "A Vértes Agorája",
        esemenykategoria: "Programkínálat, saját szervezésű rendezvény",
        minjegyar: 3500,
        description: "beck-grecso"
    },
    {
        id: 6,
        image: program_6,
        datum: "2024-02-26 18:00",
        title: "Munkatársas",
        helyszin: "A Vértes Agorája"
    },
    {
        id: 7,
        image: program_1,
        datum: "2024-01-17 18:00",
        image_detail: slide_2,
        title: "Beszt of Geszti koncert",
        helyszin: "Földi Imre Sportcsarnok",
        esemenykategoria: "Programkínálat, saját szervezésű rendezvény",
        minjegyar: 8500,
        description: "geszti"
    },
    {
        id: 8,
        image: program_2,
        datum: "2024-01-18 16:00",
        title: "Mazsola és Tádé zenés bábjáték",
        helyszin: "A Vértes Agorája"
    },
]

export const Allando_programok = [
    {
        id: 1,
        image: allando_1,
        datum: "minden szerdán 10:00",
        title: "Kerekítő - Bábos torna 1-3 éveseknek",
        helyszin: "A Vértes Agorája"
    },
    {
        id: 2,
        image: allando_2,
        datum: "minden szerdán 11:00",
        title: "Kerekítő - Mondókás torna 0-3 éveseknek",
        helyszin: "A Vértes Agorája"
    },
    {
        id: 3,
        image:allando_3,
        datum: "minden kedden 18:00",
        title: "Lakossági gimnasztika",
        helyszin: "A Vértes Agorája"
    },
    {
        id: 4,
        image: allando_4,
        datum: "bérletes előadások",
        title: "RáHangoló 2023/2024",
        helyszin: "A Vértes Agorája"
    }
]

export const Archiv_programok = [
    {
        id: 1,
        image: archiv_1,
        datum: "2023-04-15 14:00",
        title: "Agora 10 Születésnapi parti",
        helyszin: "A Vértes Agorája"
    },
    {
        id: 2,
        image: archiv_2,
        datum: "2023-04-12 19:00",
        title: "Nyári Károly & Szulák Andrea",
        helyszin: "A Vértes Agorája"
    },
    {
        id: 3,
        image: archiv_3,
        datum: "2023-04-11 18:00",
        title: "Filmvilágosító Réz András előadása",
        helyszin: "A Vértes Agorája"
    },
    {
        id: 4,
        image: archiv_4,
        datum: "2023-04-10 14:00",
        title: "Húsvét az Agoránál",
        helyszin: "A Vértes Agorája"
    },
    {
        id: 5,
        image: program_5,
        datum: "2023-03-19 18:00",
        title: "Beck@Grecsó Hoztunk valamit magunkból",
        helyszin: "A Vértes Agorája"
    },
    {
        id: 6,
        image: archiv_6,
        datum: "2023-03-27 18:00",
        title: "Rövidfilmek az SZFE hallgatóitól",
        helyszin: "A Vértes Agorája"
    },
    {
        id: 7,
        image: archiv_7,
        datum: "2023-03-26 10:00",
        title: "RáHangoló Állati jól zenélünk",
        helyszin: "A Vértes Agorája"
    },
    {
        id: 8,
        image: program_4,
        datum: "2023-03-21 19:00",
        title: "Miért pont Ázsia? Badár Sándor önálló estje",
        helyszin: "A Vértes Agorája"
    },
    {
        id: 9,
        image: program_3,
        datum: "2023-03-16 18:00",
        title: "Huzat Mazzag Izabellak",
        helyszin: "A Vértes Agorája"
    },
]

export const Hirek = [
    {
        id: 1,
        image: hirek_1,
        image_big: hirek_1_big,
        datum: "2022-11-29",
        title: "Munkatársat keresünk",
        link: "http://avertesagoraja.hu/wp-content/uploads/2023/04/%C3%A1ll%C3%A1shirdet%C3%A9s-AVA-takar%C3%ADt%C3%B3-202304.pdf",
        description: "Az Agora Nonprofit Kft. - üzemeltetésében a Vértes Agorájával és 4 városrészi művelődési házzal - meghatározó szerepet tölt be Tatabánya város kulturális életében. Olyan közösségi, szórakozási és élményszerző alkalmakat teremtünk, amelyek segítségével hasznosan és tartalmasan tölthetik el szabadidejüket valamennyi korosztály képviselői. A Vértes Agorája műszaki csoportjába keresünk munkatársat takarító/takarítónő munkakörre."
    },
    {
        id: 2,
        image: hirek_2,
        datum: "2022-11-29",
        title: "Online elérhető az új programfüzet",
        description: "Online már elérhető ITT, de napokon belül A Vértes Agorájában, valamint a város és a térség több pontján nyomtatott formában is megtalálható lesz az Agora és..."
    },
    {
        id: 3,
        image: hirek_3,
        datum: "2022-11-29",
        title: "Tartalmas családi délután szuperhősökkel",
        description: "Szuperhősök várták az őszi családi napra érkezőket az Agorában. A felnőttek is éltek a lehetőséggel, anyukák és apukák is szuperhősös pólóba, pulóverbe..."
    },
    {
        id: 4,
        image: hirek_4,
        datum: "2022-11-22",
        title: "Mellékhatás: önbizalom",
        description: "Rendkívül szórakoztató magyar filmet láthatott az Őszi filmnapok közönsége Tatabányán, az Agorában. A Zanox - Kockázatok és mellékhatások című..."
    },
    {
        id: 5,
        image: hirek_5,
        datum: "2022-11-16",
        title: "Fő az egyensúly",
        description: "Az üzleti életben mindenki csak egy fogaskerék, boldogulni pedig csak az tud, aki elvtelen, és bárkin kegyetlenül átgázol. Röviden talán ez lehetne A jó főnök..."
    },
    {
        id: 6,
        image: hirek_6,
        datum: "2022-11-29",
        title: "Kék lufiként szállni az ég felé",
        description: "Lehet-e boldog egy 3 éves kora óta vak, intézetben nevelkedett lufiárus? Többek között erre keresi a választ a „Ha egy kék lufi lennél” című rövidfilm, melyet a..."
    },
    {
        id: 7,
        image: hirek_7,
        datum: "2022-11-22",
        title: "Tinódi Lantos Sebestyéntől az AC/DC-ig",
        description: "A RáHangoló hosszú idő után újra családi koncerttel jelentkezett Tatabányán, az Agorában. A klasszikus zenei élmények befogadását megalapozni hivatott..."
    },
    {
        id: 8,
        image: hirek_8,
        datum: "2022-11-16",
        title: "Fiatalság, szépség, tehetség",
        description: "Igazi fesztivál hangulat uralkodott A Vértes Agorájában november első szombatján. Gyönyörű népviseletekbe öltözött fiatalok töltötték be a tereket, alig..."
    },
    {
        id: 9,
        image: hirek_9,
        datum: "2022-11-29",
        title: "Legalább a filmvásznon alakuljon minden úgy",
        description: "Az Agora szezonális mozis sorozata, az Őszi filmnapok egy szív- és lélekmelengető történettel indult: a Szia, Életem! című magyar vígjáték az elejétől..."
    },
    {
        id: 10,
        image: hirek_10,
        datum: "2022-11-22",
        title: "Csányi Sándor talán nagyon is jól érti",
        description: "Csányi Sándor Hogyan értsük félre a nőket? című egyszemélyes vígjátékával először 2019 októberében lépett fel Tatabányán, az Agora színpadán, s..."
    },
    {
        id: 11,
        image: hirek_11,
        datum: "2022-11-16",
        title: "Kételkedni tanított a dumaszínház",
        description: "Kőhalmi Zoltán legújabb önálló estjén – a misztikumok útvesztőjén, s mélyen szántó gondolatok szövevényén keresztül – képet festett a kritika nélküli információ..."
    }
]

export const Programfuzet = [
    {
        title: "Programfüzet",
        description: "Online már elérhető az Agora február-márciusi programfüzete.",
        image: programfuzet
    }
]

export const Vertes_agoraja =
    <>
        <div className="title-text">
            <span>A Vértes Agorája</span>
        </div>
        <div className="description-text">
            <span>A Vértes Agorája - bár egy helyrajzi számmal és postacímmel rendelkező épület neve, valójában - mára fogalommá és szimbólummá lett. Ez a név egyet jelent a minőségi szórakozással, a közösségi élettel, a magas minőségű kultúra közvetítésével, a generációk találkozási pontjával, a szellemi műhelyek születésével és virágzásával, az élethosszig tartó tanulás lehetőségével és a - minden értelemben vett - sokszínűséggel.</span>
        </div>
        <div className="description-text">
            <span>Jövőképpel és stratégiával rendelkező - a folytonos megújulásra törekvő, elhivatott emberek alkotta - szervezet működteti. Az itt dolgozók nem csupán figyelemmel vannak az igényekre, de igyekeznek újabbakat támasztani, hogy a városban és térségében élők minőségi szolgáltatást kapjanak, az ide érkezők „hűha” élménnyel távozzanak.</span>
        </div>
        <div className="description-text">
            <span>Ha azt mondjuk: A Vértes Agorája, abba beleértjük Tatabánya városrészi művelődési házait és a Kortárs Galériát is. Előbbiek Alsó- és Felsőgalla, Kertváros és Bánhida közösségi életének otthonait jelentik, utóbbi a - jegyezett - kortárs magyar képzőművészet kiállítótere.</span>
        </div>
        <div className="description-text">
            <span>A Vértes Agorája állandósága a változás, a megújulás képessége és a minőség iránti elkötelezettség.Titka, hogy a születésnapi zsúrtól, a nemzetközileg ünnepelt művészek koncertjein át a tömegeket vonzó fesztiválokig minden egyes programba ugyanazt azt az energiát, tudást, igényt fekteti bele.</span>
        </div>
    </>

export const Galeria = [
    {
        id: 1,
        firstImage: galeriaborito_1,
        datum: "2023-04-16",
        title: "Vasárnapi mesék",
        images: [
            {
                imageid: 11,
                url: galeria_1
            },
            {
                imageid: 12,
                url: galeria_2
            },
            {
                imageid: 13,
                url: galeria_3
            },
            {
                imageid: 14,
                url: galeria_4
            },
            {
                imageid: 15,
                url: galeria_5
            },
            {
                imageid: 16,
                url: galeria_6
            },
            {
                imageid: 17,
                url: galeria_7
            },
            {
                imageid: 18,
                url: galeria_8
            },
            {
                imageid: 19,
                url: galeria_9
            },
            {
                imageid: 110,
                url: galeria_10
            },
            {
                imageid: 111,
                url: galeria_11
            },
            {
                imageid: 112,
                url: galeria_12
            },
            {
                imageid: 113,
                url: galeria_13
            },
            {
                imageid: 114,
                url: galeria_14
            },
            {
                imageid: 115,
                url: galeria_15
            },
            {
                imageid: 116,
                url: galeria_16
            },
            {
                imageid: 117,
                url: galeria_17
            },
            {
                imageid: 118,
                url: galeria_18
            },

        ]
    },
    {
        id: 2,
        firstImage: galeriaborito_2,
        datum: "2023-04-15",
        title: "Gardrób vásár",
        images: [
            {
                imageid: 21,
                url: ""
            },
            
        ]
    },
    {
        id: 3,
        firstImage: galeriaborito_3,
        datum: "2023-04-13",
        title: "Zöld mindennapok",
        images: [
            {
                imageid: 31,
                url: ""
            },
            
        ]
    },
    {
        id: 4,
        firstImage: galeriaborito_4,
        datum: "2023-04-11",
        title: "Szünidei vidám gyermekműsor",
        images: [
            {
                imageid: 41,
                url: ""
            },
            
        ]
    },
    {
        id: 5,
        firstImage: galeriaborito_5,
        datum: "2023-04-05",
        title: "Black Diamond Big Band",
        images: [
            {
                imageid: 51,
                url: ""
            },
            
        ]
    },
    {
        id: 6,
        firstImage: galeriaborito_6,
        datum: "2023-03-30",
        title: "Beszéljünk róla, rólad, rólunk",
        images: [
            {
                imageid: 61,
                url: ""
            },
            
        ]
    },
    {
        id: 7,
        firstImage: galeriaborito_7,
        datum: "2023-03-27",
        title: "Szilágyi Tibor",
        images: [
            {
                imageid: 71,
                url: ""
            },
            
        ]
    },
    {
        id: 8,
        firstImage: galeriaborito_8,
        datum: "2023-03-09",
        title: "Beszéljünk róla, rólad, rólunk",
        images: [
            {
                imageid: 81,
                url: ""
            },
            
        ]
    }
]