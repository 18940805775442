// react imports
import React, { useContext, useEffect } from "react";
import ProfileForm from "../components/form-components/ProfileForm"
import GoogleMaps from "../components/googlemaps/GoogleMaps"
import AppContext from '../AppContext';
import { MDBContainer
          } from 'mdb-react-ui-kit';


const Kapcsolatpage = (props) => {

  const context = useContext(AppContext);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }, []);


  return (
    <>
    <section className="kapcsolat-map-section" id ="kapcsolat-map-section" style={{ minHeight: "calc(100vh - 617px)"}}>
      <MDBContainer style={{maxWidth: "1284px"}} className='p-0'>
        <div className="kapcsolat-map-div">
          <div className="left-col-div">
            <div className="up-div">
              <h3>Kapcsolat</h3>
              <span>A Vértes Agorája</span>
              <span>Cím: Tatabánya, Szent Borbála tér 1.</span>
              <span>Telefon: +36 34 688 100</span>
              <span>E-mail: <a href={"mailto:info@avertesagoraja.hu"}>info@avertesagoraja.hu</a>, <a href={"mailto:jegypenztar@avertesagoraja.hu"}>jegypenztar@avertesagoraja.hu</a></span>
              <span>Honlap: <a href="www.avertesagoraja.hu" target="_blank"  rel="noopener noreferrer">www.avertesagoraja.hu</a></span>
            </div>
            <div className="down-div">
              <p>Nyitvatartás:</p>
              <span>Hétfőtől szombatig 10:00-20:00</span>
              <span>Vasárnap ZÁRVA</span>
              <p>A jegypénztár nyitvatartása:</p>
              <span>Hétfőtől szombatig 10:00-19:00</span>
              <span>Vasárnap ZÁRVA</span>
              <span className="pt-2">Ebédszünet: 13:00-13:45</span>
            </div>
          </div>
          <div className="right-col-div">
            <div className="map-keret">
              <GoogleMaps/>
            </div>
          </div>
        </div>
      </MDBContainer>
    </section>
    <section className="urlap-section" id="urlap-section">
      <MDBContainer style={{maxWidth: "1284px"}} className='p-0'>
        <div className="kapcsolat-title">
          <span>Kérdése van? Miben segíthetünk?</span>
        </div>
        <ProfileForm context={context}/>
      </MDBContainer>
    </section>
    </>
  );
};

export default Kapcsolatpage;
