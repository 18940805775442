// react imports
import React from "react";
import { useNavigate } from "react-router-dom";
import program_ikon from "../../assets/media/icons/program ikon.png"
import ingyen_ikon from "../../assets/media/icons/program ikon.png"
import moment from 'moment'

const ProgramCard = (props) => {

  moment.updateLocale('hu', {
      months : [
          "Január", "Február", "Március", "Április", "Május", "Június", "Július",
          "Augusztus", "Szeptember", "Október", "November", "December"
      ]
  });
  const navigate = useNavigate();

  return (
    <div className="program-card-div"  onClick={() => navigate('/programdetailpage', {
                                                                                      state: {
                                                                                        program: props.msg,
                                                                                      }
                                                                                    })}>
        <div className="image-div" style={{backgroundImage: `url(${props.msg.image})`}}>
          {props.kovetkezoprogramok && (
            <div onClick={() => {if(props.msg.minjegyar !== "ingyenes"){
                window.open('https://www.jegy.hu/pfsearch?q=a+v%c3%a9rtes+agor%c3%a1ja&sgtype=pfhistoykeyword', '_blank')
              } }}>
              <img src={props.msg.minjegyar === "ingyenes" ? ingyen_ikon : program_ikon} className="program-ikon-image" alt=""/>
            </div>
          )}
            <div className="program-image" />
            <div class="overlay"/> 
        </div>
        <div className="datum-div">
          {props.allandoprogramok ?
            <span>{props.msg.datum}</span>:
            <span>{moment(props.msg.datum).locale('hu').format('MMMM DD., HH:mm')}</span>}
        </div>
        <div className="text-div">
            <p>{props.msg.title}</p>
            <span>{props.msg.helyszin}</span>
        </div>
    </div>
  );
};

export default ProgramCard;
