// react imports
import React from "react";

import { Kiemelt_program } from '../../data/TartalmiElemek'
import moment from 'moment'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const SliderComponent = (props) => {

moment.updateLocale('hu', {
    months : [
        "Január", "Február", "Március", "Április", "Május", "Június", "Július",
        "Augusztus", "Szeptember", "Október", "November", "December"
    ]
});
  
  const responsive = {
    
    desktop: {
        breakpoint: { max: 4000, min: 0 },
        items: 1,
        partialVisibilityGutter: 1
    }
  };

  return (
            <Carousel
                additionalTransfrom={0} 
                swipeable={true}
                draggable={false}
                showDots={false}
                responsive={responsive}
                infinite={true}
                renderArrowsWhenDisabled={true}
                renderDotsOutside={true}
                slidesToSlide={1}
                centerMode={false}
                keyBoardControl={true}
                autoPlay={true}
                autoPlaySpeed={15999}
                customTransition="transform 1000ms ease-in-out"
                removeArrowOnDeviceType={["desktop"]}
                transitionDuration={1000}
                containerClass={"carousel-container"}
                dotListClass="custom-dot-list-style"
                itemClass="carousel-item-padding-40-px"
                >
                    {Kiemelt_program.map((k, n) => {return (
                    <div className="slider-div">
                        <img
                            className='w-100'
                            itemID={k.id}
                            src={k.image}
                            alt={k.id}
                            key={n}
                        />
                        <div className="felirat">
                            <div className="felirat-datum">
                                <span>{moment(k.datum).locale('hu').format('MMMM DD., HH:mm')}</span>
                            </div>
                            <div className="felirat-szoveg">
                                <span className="title">{k.title}</span>
                                <span className="description">{k.description}</span>
                            </div>
                        </div>
                    </div>
                   
                   
                )})}
               
             
            </Carousel>
  );
};

export default SliderComponent;
