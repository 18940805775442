// REACT IMPORTS
import React, { Component, useRef, useState } from "react";
import { useNavigate, useSearchParams } from 'react-router-dom';
// MDBOOTSTRAP IMPORTS
import { 
  MDBRow, 
  MDBCol, 

  MDBCheckbox
} from 'mdb-react-ui-kit';


// STYLE IMPORTS
import "react-toggle/style.css";


class HirlevelForm extends Component {
 
  constructor(props) {
    super(props);
      this.state = {email: "",
                    name: "",  
      };
    };

    onCustomer = (e) => {
      e.preventDefault();
      var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if(this.state.email.match(validRegex)){
        //let self = this;
        let hirlevel = {
          name: this.state.name,
          email: this.state.email,
          gyerekprogram: this.props.checked_1,
          koncert: this.props.checked_2,
          film: this.props.checked_3,
          irodalom: this.props.checked_4,
          szinhaz: this.props.checked_5,
          kiallitas: this.props.checked_6,
          fesztival: this.props.checked_7
        }
        console.log(hirlevel)
        this.setState({
          email: "",
          name: "", 
        })
        this.props.setChecked(false)
        this.props.setChecked_1(false)
        this.props.setChecked_2(false)
        this.props.setChecked_3(false)
        this.props.setChecked_4(false)
        this.props.setChecked_5(false)
        this.props.setChecked_6(false)
        this.props.setChecked_7(false)
        this.props.navigate('/home')
       /* this.props.context.interAxios.post('/contact', {
                                                lastName: this.state.lastName,
                                                firstName: this.state.firstName,
                                                targy: this.state.targy,
                                                email: this.state.email,
                                                message: this.state.text
                                               }, 
        function(response) {
          self.setState({
            email: "",
            nev: "",
            text: ""
          })
        });*/

        
      }else {
        alert('Az Emailforámatum helytelen!')
      }
      
    }

    changeHandler = event => {
      this.setState({ [event.target.name]: event.target.value });
    };
  
  render() {
    
    return (<>
              <form onSubmit={(e) => this.onCustomer(e)} className="hirlevel-form" autoComplete="off">
                <div className="white-panel">
                  <MDBRow className="pb-2 m-0">
                    <MDBCol md='12' className="p-0">
                      <label htmlFor="name">Név <span>*</span></label>
                      <input
                        id='name'
                        name='name'
                        type='text'
                        onChange={this.changeHandler}
                        value={this.state.name}
                        onKeyDown={(e) => {
                          if (e.key === 'Tab') {
                            e.preventDefault();
                            e.stopPropagation();
                            this.props.emailRef.current.focus()
                          }
                        }}
                        required
                      />
                    </MDBCol>
                  </MDBRow>

                
                  <MDBRow className="pb-2 m-0">
                    <MDBCol md='12' className="p-0">
                      <label htmlFor="email">E-mail cím <span>*</span></label>
                      <input
                        id='email'
                        name='email'
                        type='text'
                        onChange={this.changeHandler}
                        value={this.state.email}
                        ref={this.props.emailRef}
                        required
                      />
                    </MDBCol>
                  </MDBRow>

                  <MDBRow className="py-2 m-0">
                    <div className="check-box-title">
                      <span>Érdeklődési kör</span>
                    </div>
                    <MDBCol md='12'  className="d-grid align-items-center justify-content-start p-0">
                      <div className="checkbox-div d-flex align-items-start pb-3">
                        <MDBCheckbox
                          id='controlledCheckbox_1'
                          label=""
                          checked={this.props.checked_1}
                          onChange={() => this.props.setChecked_1(!this.props.checked_1)}
                        /> 
                        <span>Gyerekprogram</span>
                        
                      </div>   
                    </MDBCol>
                    <MDBCol md='12'  className="d-grid align-items-center justify-content-start p-0">
                      <div className="checkbox-div d-flex align-items-start pb-3">
                        <MDBCheckbox
                          id='controlledCheckbox_2'
                          label=""
                          checked={this.props.checked_2}
                          onChange={() => this.props.setChecked_2(!this.props.checked_2)}
                        /> 
                        <span>Koncert</span>
                        
                      </div>   
                    </MDBCol>
                    <MDBCol md='12'  className="d-grid align-items-center justify-content-start p-0">
                      <div className="checkbox-div d-flex align-items-start pb-3">
                        <MDBCheckbox
                          id='controlledCheckbox_3'
                          label=""
                          checked={this.props.checked_3}
                          onChange={() => this.props.setChecked_3(!this.props.checked_3)}
                        /> 
                        <span>Film</span>
                        
                      </div>   
                    </MDBCol>
                    <MDBCol md='12'  className="d-grid align-items-center justify-content-start p-0">
                      <div className="checkbox-div d-flex align-items-start pb-3">
                        <MDBCheckbox
                          id='controlledCheckbox_4'
                          label=""
                          checked={this.props.checked_4}
                          onChange={() => this.props.setChecked_4(!this.props.checked_4)}
                        /> 
                        <span>Irodalom</span>
                        
                      </div>   
                    </MDBCol>
                    <MDBCol md='12'  className="d-grid align-items-center justify-content-start p-0">
                      <div className="checkbox-div d-flex align-items-start pb-3">
                        <MDBCheckbox
                          id='controlledCheckbox_5'
                          label=""
                          checked={this.props.checked_5}
                          onChange={() => this.props.setChecked_5(!this.props.checked_5)}
                        /> 
                        <span>Színház</span>
                        
                      </div>   
                    </MDBCol>
                    <MDBCol md='12'  className="d-grid align-items-center justify-content-start p-0">
                      <div className="checkbox-div d-flex align-items-start pb-3">
                        <MDBCheckbox
                          id='controlledCheckbox_6'
                          label=""
                          checked={this.props.checked_6}
                          onChange={() => this.props.setChecked_6(!this.props.checked_6)}
                        /> 
                        <span>Kiállítás</span>
                        
                      </div>   
                    </MDBCol>
                    <MDBCol md='12'  className="d-grid align-items-center justify-content-start p-0">
                      <div className="checkbox-div d-flex align-items-start pb-3">
                        <MDBCheckbox
                          id='controlledCheckbox_7'
                          label=""
                          checked={this.props.checked_7}
                          onChange={() => this.props.setChecked_7(!this.props.checked_7)}
                        /> 
                        <span>Fesztivál</span>
                        
                      </div>   
                    </MDBCol>
                  </MDBRow>


                

                  <MDBRow className="py-2 m-0">
                    <MDBCol md='12'  className="d-grid align-items-center justify-content-start p-0">
                      <div className="checkbox-div d-flex align-items-start pb-4">
                        <MDBCheckbox
                          id='controlledCheckbox_1'
                          label=""
                          checked={this.props.checked}
                          onChange={() => this.props.setChecked(!this.props.checked)}
                          required
                        /> 
                        <span>Hozzájárulok, hogy a hírlevél feliratkozással összefüggésben az Agora Nonprofit Kft. a személyes adataimat az 
                          <a href={'/aszf'} target="_blank" rel="noopener noreferrer">Adatkezelési tájékoztatóban </a>foglaltak szerint kezelje.<span className="stern">*</span></span>
                        
                      </div>   
                    </MDBCol>
                  </MDBRow>

                  <MDBRow className="pb-3 m-0">
                    <MDBCol md='12'  className="d-grid align-items-center justify-content-start p-0" >
                          <div>
                            <button type="submit" className="muster-button kiemelt" >
                            <span>FELIRATKOZÁS</span>
                            </button>
                          </div>
                    </MDBCol>
                  </MDBRow>
           
                
                  </div>
              </form>

               
      </>
    );
  }
};

function HirlevelFormFunc(props) {
  let [searchParams] = useSearchParams();
  const [checked, setChecked] = useState(false);
  const [checked_1, setChecked_1] = useState(false);
  const [checked_2, setChecked_2] = useState(false);
  const [checked_3, setChecked_3] = useState(false);
  const [checked_4, setChecked_4] = useState(false);
  const [checked_5, setChecked_5] = useState(false);
  const [checked_6, setChecked_6] = useState(false);
  const [checked_7, setChecked_7] = useState(false);
  const navigate = useNavigate();
  const emailRef = useRef();
  return <HirlevelForm searchParams={searchParams} 
                      navigate={navigate} 
                      context={props.context} 
                      emailRef={emailRef}
                      checked={checked}
                      setChecked={setChecked}
                      checked_1={checked_1}
                      setChecked_1={setChecked_1}
                      checked_2={checked_2}
                      setChecked_2={setChecked_2}
                      checked_3={checked_3}
                      setChecked_3={setChecked_3}
                      checked_4={checked_4}
                      setChecked_4={setChecked_4}
                      checked_5={checked_5}
                      setChecked_5={setChecked_5}
                      checked_6={checked_6}
                      setChecked_6={setChecked_6}
                      checked_7={checked_7}
                      setChecked_7={setChecked_7}
                      />;
}

export default HirlevelFormFunc;
