// REACT IMPORTS
import { useContext,useState } from "react";
import { useNavigate } from "react-router-dom";


// CUSTOM COMPONENT IMPORTS
import AppContext from '../../../AppContext';
import { MenuList } from '../../../data/NavbarMenuList'
import ava_logo from "../../../assets/media/logos/ava_logo.png"
import search from "../../../assets/media/icons/Keresés ikon.png"
import user from "../../../assets/media/icons/user.png"

import {
    MDBContainer,
    MDBNavbar,
    MDBNavbarBrand,
    MDBNavbarNav,
    MDBNavbarItem,
    MDBNavbarLink,
    MDBDropdown,
    MDBDropdownToggle,
    MDBDropdownMenu,
    MDBDropdownItem,
} from 'mdb-react-ui-kit';


const NavbarComponent = ({props}) => {

  const context = useContext(AppContext);
  const navigate = useNavigate();
  // STATES
  const [dropName, setDropName] = useState();

  const pathname = "/" + window.location.pathname.split("/")[window.location.pathname.split("/").length-1];
  return (
    <MDBNavbar expand='lg' light bgColor='light'>
      <MDBContainer style={{maxWidth: "1256px", height: "100%"}} className='p-0'>
        <MDBNavbarBrand onClick={() => {context.onSetActiveDopItem(-1); context.onSetActiveItem(-1); navigate('/home')}}>
            <img src={ava_logo} alt="ava-logo" loading='lazy'/>
        </MDBNavbarBrand>
        <div className=" d-flex justify-content-end align-items-center">
          <MDBNavbarNav className='mr-auto mb-2 mb-lg-0 align-items-center'>
            {MenuList.map((k,n) => {return (
              <MDBNavbarItem key={n} >
                {k.dropddown_list.length === 0 ? 
                <MDBNavbarLink onClick={() => {context.onSetActiveItem(n); context.onSetActiveDopItem(-1); setDropName(); navigate(k.link)}} active={context.getState()?.activeItem === n || pathname === k.link} aria-current='page'>
                  {k.title}
                </MDBNavbarLink>:
                <MDBDropdown>
                  <MDBDropdownToggle tag='a' className={(context.getState()?.activeItem === n || k.dropddown_list.map(link => {return (link.link)}).includes(pathname)) ? 'nav-link active' : 'nav-link'} role='button'>
                    {k.title}
                  </MDBDropdownToggle>
                  <MDBDropdownMenu>
                    {k.dropddown_list.map((l,m) => {return (
                      <MDBDropdownItem key={m} onClick={() => {context.onSetActiveItem(n); context.onSetActiveDopItem(m); setDropName(l.title); navigate(l.link)}}>
                        <MDBNavbarLink active={(context.getState()?.activeDropItem === m && dropName === l.title) || pathname === l.link} aria-current='page'>
                          {l.title}
                        </MDBNavbarLink>
                      </MDBDropdownItem>
                    )})}
                  </MDBDropdownMenu>
                </MDBDropdown>}
              </MDBNavbarItem>
            )})}
          <button className="jegyvasarlas-button" onClick={() => window.open('https://www.jegy.hu/pfsearch?q=a+v%c3%a9rtes+agor%c3%a1ja&sgtype=pfhistoykeyword', '_blank')}>
            <span>Jegyvásárlás</span>
          </button>
          <button className="search-button">
            <img src={search} alt="Keresés ikon"/>
          </button>
              <MDBNavbarItem >
                <MDBDropdown>
                  <MDBDropdownToggle tag='a' className='nav-link ms-3' role='button'>
                    <img src={user} alt="user"/>
                  </MDBDropdownToggle>
                  <MDBDropdownMenu>
                    {context.isLoggedIn ? <>
                      <MDBDropdownItem onClick={() => {navigate('/belepes')}}>
                        <MDBNavbarLink aria-current='page'>
                          Belépés
                        </MDBNavbarLink>
                      </MDBDropdownItem>
                      <MDBDropdownItem onClick={() => {navigate('/regisztracio')}}>
                        <MDBNavbarLink aria-current='page'>
                          Regisztráció
                        </MDBNavbarLink>
                      </MDBDropdownItem>
                    
                    </>:
                    <MDBDropdownItem onClick={() => {context.onLogout(); navigate('/home')}}>
                      <MDBNavbarLink aria-current='page'>
                        Kilépés
                      </MDBNavbarLink>
                    </MDBDropdownItem>
                  }
                  </MDBDropdownMenu>
                </MDBDropdown>
              </MDBNavbarItem>
          </MDBNavbarNav>
        </div>
      </MDBContainer>
    </MDBNavbar>
  );
};

export default NavbarComponent