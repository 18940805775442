// react imports
import React from "react";
import { useNavigate } from "react-router-dom";
import moment from 'moment'

const HirekCard = (props) => {

  moment.updateLocale('hu', {
    months : [
        "Január", "Február", "Március", "Április", "Május", "Június", "Július",
        "Augusztus", "Szeptember", "Október", "November", "December"
    ]
  }); 
  const navigate = useNavigate();
  return (
    <div className="hirek-card-div" onClick={() => navigate('/hirekdetailpage', {
                                                                                    state: {
                                                                                      hirek: props.msg,
                                                                                    }
                                                                                  })}>
        <div className="image-div">
            <img src={props.msg.image} alt={props.msg.id}/>
        </div>
        <div className="title-div">
            <p>{props.msg.title}</p>
            <span>{moment(props.msg.datum).locale('hu').format('YYYY.MM.DD')}</span>
        </div>
        <div className="text-div">
            <span>{props.msg.description}</span>
        </div>
    </div>
  );
};

export default HirekCard;
