// REACT IMPORTS
import React, { Component, useRef, useState } from "react";
import { useNavigate, useSearchParams } from 'react-router-dom';
// MDBOOTSTRAP IMPORTS
import { 
  MDBRow, 
  MDBCol, 
  MDBInputGroup,
  MDBIcon,
  MDBCheckbox
} from 'mdb-react-ui-kit';


// STYLE IMPORTS
import "react-toggle/style.css";


class RegisztracioForm extends Component {
 
  constructor(props) {
    super(props);
      this.state = {email: "",
                    name: "",  
      };
    };

    onCustomer = (e) => {
      e.preventDefault();
      var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if(this.state.email.match(validRegex)){

        if(this.state.password === this.state.confirmPassword){
          this.props.context.interAxios.post('/auth/registration',
          {
            email: this.state.email,
            password: this.state.password,
            name: this.state.name,
           
          }, function(response) {});
        }else {
          alert('Nem megfelelő jelszó!')
        }
        
        this.setState({
          email: "",
          name: "", 
          password: ""
        })
        this.props.setChecked(false)
        
        this.props.navigate('/home')
      
      }else {
        alert('Az emailforámatum helytelen!')
      }
      
    }

    changeHandler = event => {
      this.setState({ [event.target.name]: event.target.value });
    };
  
  render() {
    
    return (<>
              <form onSubmit={(e) => this.onCustomer(e)} className="hirlevel-form" autoComplete="off">
                <div className="white-panel">
                  <MDBRow className="pb-2 m-0">
                    <MDBCol md='12' className="p-0">
                      <label htmlFor="name">Név <span>*</span></label>
                      <input
                        id='name'
                        name='name'
                        type='text'
                        onChange={this.changeHandler}
                        value={this.state.name}
                        onKeyDown={(e) => {
                          if (e.key === 'Tab') {
                            e.preventDefault();
                            e.stopPropagation();
                            this.props.emailRef.current.focus()
                          }
                        }}
                        required
                      />
                    </MDBCol>
                  </MDBRow>

                
                  <MDBRow className="pb-2 m-0">
                    <MDBCol md='12' className="p-0">
                      <label htmlFor="email">E-mail cím <span>*</span></label>
                      <input
                        id='email'
                        name='email'
                        type='text'
                        onChange={this.changeHandler}
                        value={this.state.email}
                        ref={this.props.emailRef}
                        required
                      />
                    </MDBCol>
                  </MDBRow>
                  {/** PASSWORDs **/}
                  <MDBRow className="pb-3 m-0">
                    <MDBCol md='12' className="p-0">
                      <label htmlFor="password">Jelszó <span>*</span></label><br/>
                      <MDBInputGroup
                        noWrap 
                        textAfter={(
                          <MDBIcon 
                            className={"fa-regular" + (this.props.hidePassword ? ' fa-eye' : ' fa-eye-slash')}
                            onClick={() => this.props.setHidePassword(!this.props.hidePassword)}
                          ></MDBIcon>
                        )}
                      >
                        <input 
                          type={this.props.hidePassword ? 'password' : 'text'} 
                          value={this.state.password}
                          onChange={this.changeHandler}
                          ref={this.props.passwordRef}
                          id='password'
                          name='password'
                          onKeyDown={(e) => {
                            if (e.key == 'Tab') {
                              e.preventDefault();
                              e.stopPropagation();
                              this.props.passwordtwoRef.current.focus()
                            }
                          }}
                          required
                        />
                      </MDBInputGroup>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow className="pb-3 m-0">
                    <MDBCol md='12' className="p-0">
                      <label htmlFor="password">Jelszó újra <span>*</span></label><br/>
                      <MDBInputGroup
                        noWrap 
                        textAfter={(
                          <MDBIcon 
                            className={"fa-regular" + (this.props.hidePasswordtwo ? ' fa-eye' : ' fa-eye-slash')}
                            onClick={() => this.props.setHidePasswordtwo(!this.props.hidePasswordtwo)}
                          ></MDBIcon>
                        )}
                      >
                        <input 
                          type={this.props.hidePasswordtwo ? 'password' : 'text'} 
                          value={this.state.confirmPassword}
                          onChange={this.changeHandler}
                          ref={this.props.passwordtwoRef}
                          id='confirmPassword'
                          name='confirmPassword'
                          required
                        />
                      </MDBInputGroup>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow className="pt-1 pb-4 m-0">
                    <span className="jelszo-text p-0">A jelszó legalább 6 karakter hosszú legyen és tartalmazzon legalább egy számjegyet, egy kis és egy nagybetűt.</span>
                  </MDBRow>
                  <MDBRow className="pt-2 pb-4 m-0">
                    <MDBCol md='12'  className="d-grid align-items-center justify-content-start p-0">
                      <div className="checkbox-div d-flex align-items-start pb-1">
                        <MDBCheckbox
                          id='controlledCheckbox_1'
                          label=""
                          checked={this.props.checked}
                          onChange={() => this.props.setChecked(!this.props.checked)}
                          required
                        /> 
                        <span>Elolvastam és elfogadom az 
                          <a href={'/aszf'} target="_blank" rel="noopener noreferrer"> adatvédelmi tájékoztatót</a>.<span className="stern">*</span></span>
                        
                      </div>   
                    </MDBCol>
                    <MDBCol md='12'  className="d-grid align-items-center justify-content-start px-0 pt-2">
                      <div className="checkbox-div d-flex align-items-start pb-1">
                        <MDBCheckbox
                          id='controlledCheckbox_2'
                          label=""
                          checked={this.props.checked_2}
                          onChange={() => this.props.setChecked_2(!this.props.checked_2)}
                          required
                        /> 
                        <span>Hozzájárulok, hogy az általam megadott szeményes adatokat az Adatkezelő az Adatkezelési tájékoztatóban meghatározott célból kezelje.<span className="stern">*</span></span>
                        
                      </div>   
                    </MDBCol>
                  </MDBRow>

                  

                  <MDBRow className="pb-3 m-0">
                    <MDBCol md='12'  className="d-grid align-items-center justify-content-start p-0" >
                          <div>
                            <button type="submit" className="muster-button kiemelt" >
                            <span>REGISZTRÁCIÓ</span>
                            </button>
                          </div>
                    </MDBCol>
                  </MDBRow>
           
                
                  </div>
              </form>

               
      </>
    );
  }
};

function RegisztracioFormFunc(props) {
  let [searchParams] = useSearchParams();
  const [hidePassword, setHidePassword] = useState(true);
  const [hidePasswordtwo, setHidePasswordtwo] = useState(true);
  const [checked, setChecked] = useState(false);
  const [checked_2, setChecked_2] = useState(false);
  const navigate = useNavigate();
  const emailRef = useRef();
  const passwordfirstRef = useRef();
  const passwordtwoRef = useRef();
  return <RegisztracioForm searchParams={searchParams} 
                      navigate={navigate} 
                      context={props.context} 
                      emailRef={emailRef}
                      passwordfirstRef={passwordfirstRef}
                      passwordtwoRef={passwordtwoRef}
                      checked={checked}
                      setChecked={setChecked}
                      checked_2={checked_2}
                      setChecked_2={setChecked_2}
                      hidePassword={hidePassword}
                      setHidePassword={setHidePassword}
                      hidePasswordtwo={hidePasswordtwo}
                      setHidePasswordtwo={setHidePasswordtwo}
                      />;
}

export default RegisztracioFormFunc;
