// react imports
import React, { useState, useEffect } from "react";
// CUSTOM COMPONENTS
import GaleriaKategoriaCard from "../components/cards/GaleriaKategoriaCard"

import { Galeria } from '../data/TartalmiElemek'


import { MDBContainer, 
         MDBCol, 
         MDBRow
          } from 'mdb-react-ui-kit';


const GaleriaKategoriaPage = (props) => {

  // STATES
  const [fullItemList, setFullItemList] = useState([]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
    setFullItemList(Galeria);
  }, []);


  const loadLessGeleria = () => {
    
  };
  const loadMoreGeleria = () => {
    
  };
  

  return (
    <>
    <section className="galeria-kategoria-section" id ="galeria-kategoria-section" style={{paddingBottom: "85px", minHeight: "calc(100vh - 617px"}}>
      <MDBContainer style={{maxWidth: "1284px"}} className='p-0'>
            <div>
              <div className="galeria-title">
                <span>Galéria</span>
              </div>
              <MDBRow className="galeria-row">
                {fullItemList.map((k,n) => {return (
                  <MDBCol md="3" className="galeria-col" key={n}>
                    <GaleriaKategoriaCard msg={k}/>
                  </MDBCol>
                )})}
            
              </MDBRow>
            </div>
            <div className="loadmore-div">
              <button type="button" onClick={loadLessGeleria}><span>Előző oldal</span></button>
              <button type="button" onClick={loadMoreGeleria}><span>Következő oldal</span></button>
            </div>
      </MDBContainer>
    </section>
    </>
  );
};

export default GaleriaKategoriaPage;
