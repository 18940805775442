// react imports
import React from "react";
import { useNavigate } from "react-router-dom";
import moment from 'moment'

const GaleriaKategoriaCard = (props) => {

  
  const navigate = useNavigate();

  return (
    <div className="program-card-div"  onClick={() => navigate('/galeriadetailpage', {
                                                                                      state: {
                                                                                        item: props.msg,
                                                                                      }
                                                                                    })}>
        <div className="image-div">
            <img src={props.msg.firstImage} alt={props.msg.id}/>
        </div>
        <div className="datum-div">
            <span>{moment(props.msg.datum).locale('hu').format('YYYY.MM.DD.')}</span>
        </div>
        <div className="text-div">
            <span>{props.msg.title}</span>
        </div>
    </div>
  );
};

export default GaleriaKategoriaCard;
