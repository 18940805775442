// REACT IMPORTS
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// MDBOOTSTRAP IMPORTS
import { MDBContainer } from "mdb-react-ui-kit";

import { FooterDate } from '../../../data/FooterDate'

import szechenyi_logo from "../../../assets/media/logos/Szechenyi_logo.png"
import ava_logo_feher from "../../../assets/media/logos/ava_logo_feher.png"
import facebook from "../../../assets/media/icons/Facebook.png"
import youtube from "../../../assets/media/icons/Youtube.png"
import instagram from "../../../assets/media/icons/Instagram.png"
import facebook_white from "../../../assets/media/icons/Facebook white.png"
import youtube_white from "../../../assets/media/icons/Youtube white.png"
import instagram_white from "../../../assets/media/icons/Instagram white.png"
import nka from "../../../assets/media/logos/nka_2023.png"


const Footer = (props) => {
  const navigate = useNavigate();

  const [onMouseOverFacebook, setOnMouseOverFacebook] = useState(false);
  const [onMouseOverYoutube, setOnMouseOverYoutube] = useState(false);
  const [onMouseOverInstagram, setOnMouseOverInstagram] = useState(false);


  const onAddNewsletter = () => {
     navigate('/hirlevelpage')
  }

  return (
    <footer className="agora-footer" id="agora-footer">
       {/* <img className="szechenyi-logo" src={szechenyi_logo} alt="szechenyi-logo"/>*/}
        <MDBContainer style={{maxWidth: "1260px", height: "100%"}} className='p-0'>
          <div className="footer-div">
            <div className="footer-culomn-div">
              <div className="elso-oszlop">
                <img src={ava_logo_feher} alt="ava-logo"/>
                <p>Elérhetőség</p>
                <span>{FooterDate[0].elerhetoseg.cim}</span><br/>
                <span>{FooterDate[0].elerhetoseg.telefon}</span><br/>
                <span>{FooterDate[0].elerhetoseg.email}</span><br/><br/>
                <span>Impresszum</span><br/>
                <span>Írjon nekünk!</span>
              </div>
              <div className="masodik-oszlop">
                <p>Nyitvatartás</p>
                <span>{FooterDate[1].nyitvatartas.hetkozi}</span><br/>
                <span>{FooterDate[1].nyitvatartas.hetvegi}</span>
                <p className="jegyvasarlas">Jegypénztás nyitvatartása:</p>
                <span>{FooterDate[2].jegypenztarnyitvatartas.hetkozi}</span><br/>
                <span>{FooterDate[2].jegypenztarnyitvatartas.hetvegi}</span><br/>
                <span>{FooterDate[2].jegypenztarnyitvatartas.ebedido}</span>
              </div>
              <div className="harmadik-oszlop">
                <p>Kövessen minket</p>
                <img src={!onMouseOverFacebook ? facebook : facebook_white} alt="facebook" onMouseOver={() => setOnMouseOverFacebook(true)} onMouseLeave={() => setOnMouseOverFacebook(false)} />
                <img src={!onMouseOverYoutube ? youtube : youtube_white} alt="youtube" onMouseOver={() => setOnMouseOverYoutube(true)} onMouseLeave={() => setOnMouseOverYoutube(false)} />
                <img src={!onMouseOverInstagram ? instagram : instagram_white} alt="instagram" onMouseOver={() => setOnMouseOverInstagram(true)} onMouseLeave={() => setOnMouseOverInstagram(false)} />
                <p>Iratkozzon fel </p>
               
                <button type="button" className="feliratkozom-button" style={{width: "189px !important"}} onClick={() => onAddNewsletter()}><span>FELIRATKOZOM</span></button>
                <img className="nka-logo" src={nka} alt="nka" height='83'/>
              </div>


            </div>
            <div className="footer-licensz-div">
              <span>{FooterDate[3].licensz}</span>
            </div>
          </div>
        </MDBContainer>
       
     	
    </footer>
  );
};

export default Footer;
