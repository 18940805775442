// REACT IMPORTS
import NavbarComponent from "./NavbarComponent"

import telefon from "../../../assets/media/icons/telefon ikon.png"
import ora from "../../../assets/media/icons/ora ikon.png"
import email from "../../../assets/media/icons/email ikon.png"

import {
  MDBContainer
} from 'mdb-react-ui-kit';



const Header = ({props}) => {



 
  return (
    <header className="agora-header">
      <div className="information-bar">
        <MDBContainer style={{maxWidth: "1256px", height: "100%"}} className='p-0'>
          <div className="d-flex align-items-center justify-content-start" style={{height: "100%"}}>
            <div className="d-flex align-items-center justify-content-start">
              <img className="info-ikon" src={telefon} alt="telefon"/>
              <span className="info-text">+36 34 688 100</span>
            </div>
            <div className="d-flex align-items-center justify-content-start">
              <img className="info-ikon" src={email} alt="telefon"/>
              <span className="info-text">info@avertesagoraja.hu</span>
            </div>
            <div className="d-flex align-items-center justify-content-start">
              <img className="info-ikon" src={ora} alt="telefon"/>
              <span className="info-text">H-SZ: 10:00-20:00</span>
            </div>
          </div>
        </MDBContainer>
      </div>
      <NavbarComponent/>
    </header>
  );
};

export default Header
