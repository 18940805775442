// react imports
import React, { useEffect } from "react";
import RegisztracioForm from "../components/form-components/RegisztracioForm"

import { MDBContainer
          } from 'mdb-react-ui-kit';


const RegisztracioPage = (props) => {


  useEffect(() => {
    setTimeout(() => window.scrollTo({
      top: 0,
      behavior: "smooth"
    }), 100);
    
  }, []);


  return (
    <>
    <section className="hirlevelpage-section" id ="hirlevelpage-section">
      <MDBContainer style={{maxWidth: "1284px"}} className='p-0'>
        <div className="hirlevel-title">
          <span>Regisztráció</span>
        </div>
      </MDBContainer>
    </section>
    <section className="hirlevelform-section" id ="hirlevelform-section" style={{paddingBottom: "85px"}}>
       <MDBContainer style={{maxWidth: "1284px"}} className='p-0'>
        <div className="regisztracioform-div">
          <div className="mb-3">
            <span className="title-text ">A következő adatok szükségesek a profil létrehozásához.</span><br/>
            <span className="description-text ">A megadott e-mail címre egy visszaigazoló e-mailt küldünk.</span>
          </div>
          <RegisztracioForm/>
        </div>
      </MDBContainer>
    </section>
    </>
  );
};

export default RegisztracioPage;
