const { 
    REACT_APP_STRIPE_KEY,
    REACT_APP_STRIPE_RETURN_URL,
    REACT_APP_GOOGLE_OAUTH_ID,
    REACT_APP_APPLELOGIN_CLIENT_ID,
    REACT_APP_APPLELOGIN_REDIRECT_URI
} = process.env;



//DEV


 export const FacebookAppId = {
     appId: '705257898003162'
 };

 export const GoogleOAuthId = {
     clientId: '437985034813-sks4k0nctifk7it0p8eqea0j9tk0uhi8.apps.googleusercontent.com'
 };

 export const AppleLogin = {
     clientId: 'com.interb2b.jnc-world',
     scope: 'email name',
     //redirectURI: 'https://3b69-2001-4c4c-1d21-f200-9081-2dde-6fda-4931.ngrok-free.app',
     redirectURI: 'http://jnc-world.interb2b.com',
     state: 'state',
     nonce: 'nonce',
     usePopup: true,
 };



// CI/CD
/*

export const FacebookAppId = {
    appId: '867735527616739'
};

export const GoogleOAuthId = {
    clientId: REACT_APP_GOOGLE_OAUTH_ID
};

export const AppleLogin = {
    clientId: REACT_APP_APPLELOGIN_CLIENT_ID,
    scope: 'email name',
    redirectURI: REACT_APP_APPLELOGIN_REDIRECT_URI,
    state: 'state',
    nonce: 'nonce',
    usePopup: true,
};
*/

