// react imports
import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
// CUSTOM COMPONENTS


import { MDBContainer, 
         MDBCol, 
         MDBRow,
         MDBLightbox, MDBLightboxItem
          } from 'mdb-react-ui-kit';


const GaleriaPage = (props) => {

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }, []);


  return (
    <>
    <section className="galeria-kategoria-section" id ="galeria-kategoria-section" style={{paddingBottom: "85px", minHeight: "calc(100vh - 617px"}}>
      <MDBContainer style={{maxWidth: "1284px"}} className='p-0'>
            <div>
              <div className="galeria-title">
                <span>{location.state.item.title}</span>
              </div>
              <MDBLightbox>
              <MDBRow className="m-0 galeria-row">
                {location.state.item.images.map((k,n) => {return (
                  
                  <MDBCol md="2" className="galeria-col" key={n}>
                    <div>
                      <MDBLightboxItem src={k.url} fullscreenSrc={k.url} alt={k.id}/>
                    </div>
                  </MDBCol>
                  
                )})}
            
              </MDBRow>
              </MDBLightbox>
            </div>
            <div className="loadmore-div">
              <button type="button" onClick={() => navigate('/galeria')}><span>Vissza a galériába</span></button>
            </div>
      </MDBContainer>
    </section>
    </>
  );
};

export default GaleriaPage;
