import React, { Component } from 'react';
import AppContext from './AppContext';
import InterAxios from './Service/InterAxios';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

const getAuthToken = () => {
  return cookies.get('authtoken')?.token;
}


class AppContextProvider extends Component {

  constructor(props) {
    super(props);
    this.state = {
      interAxios: new InterAxios('App', getAuthToken),
      activeDropItem: -1,
      activeItem: -1
    };
  }

  componentDidMount() {
}

  
  setState(state) {
    super.setState(function(prevState, props) {
      let newState = {...prevState, ...state};
      window.localStorage.setItem('state', JSON.stringify(newState));
      return {
        ...newState
      };
    })
  }


  getState = () => {
    let stateStr = window.localStorage.getItem('state');
    let stateObj = JSON.parse(stateStr);
    return stateObj;
  }

  clearState = () => {

  }

  onSignIn = (responseData) => {
    cookies.set('authtoken', {
        token: responseData.authToken
      }, { path: '/'}
    );
    this.setState({loggedIn: true});
  }

  /*onSignIn = () => {
    this.setState({loggedIn: true, isGyik: false});
  }*/

  onLogout = () => {
   cookies.remove('authtoken', { path: '/'});
    window.localStorage.removeItem('state');
    this.setState({loggedIn: false});
  }


  isLoggedIn = () => {
      let cookieToken = getAuthToken();
      return cookieToken !== undefined && this.parseJwt(cookieToken)?.exp > (Date.now() / 1000);
      //return this.state.loggedIn ? true : false;
  }

  parseJwt = (token) => {
      try {
          var base64Url = token.split('.')[1];
          var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
          var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
              return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
          }).join(''));

          return JSON.parse(jsonPayload);
      } catch (e) {
          console.log(e);
        return null;
      }
  };

  

  openSocialLoginModal = (modalName, token, authorizationCode, socialType) => {
    this.setState({
      loginModalOpened: true,
      modalName: modalName,
      accessToken: token,
      authorizationCode: authorizationCode,
      socialType: socialType
    });
  }

  openLoginModal = (modalName) => {
    this.setState({
      loginModalOpened: true,
      modalName: modalName
    });
  }

  isLoginModalOpened = () => {
    return this.getState()?.loginModalOpened;
  }
  closeLoginModal = () => {
    this.setState({
      loginModalOpened: false,
    });
    document.body.style.overflowY = 'scroll'
  }


  onRender = () => {
    this.setState({isRender: false})
  }

  onRenderTrue = () => {
    this.setState({isRender: true})
  }

  onSetActiveItem = (tab) => {
    this.setState({activeItem: tab})
  }

  onSetActiveDopItem = (tab) => {
    this.setState({activeDropItem: tab})
  }
  
  onSetActiveItemRespo = (tab) => {
    this.setState({activeItemRespo: tab})
  }

  onSetIsScroll = (value) => {
    this.setState({isScroll: value})
  }

  onSetPathName = (pathname) => {
    this.setState({pathname: pathname})
  }



  render() {
      return (
          <AppContext.Provider
              value={{
                interAxios: this.state.interAxios,
                isLoggedIn: this.isLoggedIn,
                onSignIn: this.onSignIn,
                onLogout: this.onLogout,
                getState: this.getState,
                clearState: this.clearState,
                onRender: this.onRender,
                onRenderTrue: this.onRenderTrue,
                onSetPathName: this.onSetPathName,
                onSetActiveItem: this.onSetActiveItem,
                onSetActiveDopItem: this.onSetActiveDopItem,
                isLoginModalOpened: this.isLoginModalOpened,
                openLoginModal: this.openLoginModal,
                openSocialLoginModal: this.openSocialLoginModal,
                closeLoginModal: this.closeLoginModal,
              }}
          >
              {this.props.children}
          </AppContext.Provider>
      );
  }
}

export default AppContextProvider;
