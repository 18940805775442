// react imports
import React, { useEffect } from "react";
import HirlevelForm from "../components/form-components/HirlevelForm"

import { MDBContainer
          } from 'mdb-react-ui-kit';


const HirlevelFeliratkozasPage = (props) => {


  useEffect(() => {
    setTimeout(() => window.scrollTo({
      top: 0,
      behavior: "smooth"
    }), 100);
    
  }, []);


  return (
    <>
    <section className="hirlevelpage-section" id ="hirlevelpage-section">
      <MDBContainer style={{maxWidth: "1284px"}} className='p-0'>
        <div className="hirlevel-title">
          <span>Hírlevél feliratkozás</span>
        </div>
      </MDBContainer>
    </section>
    <section className="hirlevelform-section" id ="hirlevelform-section" style={{paddingBottom: "85px"}}>
       <MDBContainer style={{maxWidth: "1284px"}} className='p-0'>
        <div className="hirlevelform-div">
          <HirlevelForm/>
        </div>
      </MDBContainer>
    </section>
    </>
  );
};

export default HirlevelFeliratkozasPage;
