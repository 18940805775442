// react imports
import React, { useState, useEffect } from "react";
// CUSTOM COMPONENTS
import HirekCard from "../components/cards/HirekCard"

import { Hirek } from '../data/TartalmiElemek'


import { MDBContainer, 
         MDBCol, 
         MDBRow
          } from 'mdb-react-ui-kit';


const HirekListPage = (props) => {


  // STATES
  const [fullHirekList, setFullHirekList] = useState([]);
  const [moreProductsAvailable, setMoreProductsAvailable] = useState(true);
  
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
    setFullHirekList(Hirek);
  }, []);

  const loadMoreHirek = () => {
    var arrayB= Hirek
    var newArray = fullHirekList.concat(arrayB);
    setFullHirekList(newArray)
    setMoreProductsAvailable(true)
  };


  return (
    <>
   
    <section className="hirekpage-section" id="hirekpage-section">
      <MDBContainer style={{maxWidth: "1284px"}} className='p-0 hirek-div'>
        <div className="hirek-title">
          <span>Hírek</span>
        </div>
        <MDBRow className="m-0 hirek-row">
          {fullHirekList.filter((n, index) => index > 1).map((k,n) => {return (
            <MDBCol md="4" className="hirek-col" key={n}>
              <HirekCard msg={k}/>
            </MDBCol>
          )})}
          
        </MDBRow>
        {moreProductsAvailable && (
        <div className="loadmore-div">
          <button type="button" onClick={loadMoreHirek}><span>Továbbiak</span></button>
        </div>)}
      </MDBContainer>
    </section>
   
    </>
  );
};

export default HirekListPage;
