// react imports
import React, { useState, useEffect } from "react";
import 'react-calendar/dist/Calendar.css';
import moment from 'moment'
// CUSTOM COMPONENTS
import ProgramCard from "../components/cards/ProgramCard"

import { Allando_programok } from '../data/TartalmiElemek'


import { MDBContainer, 
         MDBCol, 
         MDBRow
          } from 'mdb-react-ui-kit';


const AllandoProgramokPage = (props) => {


  moment.updateLocale('hu', {
    months : [
        "Január", "Február", "Március", "Április", "Május", "Június", "Július",
        "Augusztus", "Szeptember", "Október", "November", "December"
    ]
  }); 

  // STATES
  const [fullItemList, setFullItemList] = useState([]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
    setFullItemList(Allando_programok);
  }, []);


  return (
    <>
    <section className="kovetkezo-programok-section" id ="kovetkezo-programo-section" style={{paddingBottom: "85px", minHeight: "calc(100vh - 617px"}}>
      <MDBContainer style={{maxWidth: "1284px"}} className='p-0'>
            <div>
              <div className="program-title">
                <span>Állandó programok</span>
              </div>
              <MDBRow className="m-0 programok-row">
                {fullItemList.map((k,n) => {return (
                  <MDBCol md="4" className="programok-col" key={n}>
                    <ProgramCard msg={k} allandoprogramok={true}/>
                  </MDBCol>
                )})}
            
              </MDBRow>
            </div>
      </MDBContainer>
    </section>
    </>
  );
};

export default AllandoProgramokPage;
