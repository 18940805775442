// react imports
import React, { useState, useEffect } from "react";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import moment from 'moment'
// CUSTOM COMPONENTS
import ProgramCard from "../components/cards/ProgramCard"

import { Kovetkezo_programok } from '../data/TartalmiElemek'

import searchikon from "../assets/media/icons/search ikon.png"
import calendarikon from "../assets/media/icons/calendar.png"

import { MDBContainer, 
         MDBCol, 
         MDBRow,
         MDBInput
          } from 'mdb-react-ui-kit';


const ProgramokListPage = (props) => {

  moment.updateLocale('hu', {
    months : [
        "Január", "Február", "Március", "Április", "Május", "Június", "Július",
        "Augusztus", "Szeptember", "Október", "November", "December"
    ]
  }); 

  // STATES
  const [fullItemList, setFullItemList] = useState([]);
  const [monthList, setMonthList] = useState([]);
  const [isActuelleProgram, setIsActuelleProgram] = useState(false);
  const [isSearchFunction, setIsSearchFunction] = useState(false);
  const [actuelleDate, setActuelleDate] = useState();
  const [searchText, setSearchText] = useState("")

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
    setFullItemList(Kovetkezo_programok);
    
    const monthList = Kovetkezo_programok.map(k => {return ( moment(k.datum).locale('hu').format('MMMM') )})
    const set = new Set(monthList)
    const arr = Array.from(set);
    setMonthList(arr)
  }, []);


  const currentdate = new Date();
  const firstDay = new Date(currentdate.getFullYear(), currentdate.getMonth(), 1);
  const lastDay = new Date(currentdate.getFullYear(), currentdate.getMonth() + 1, 0);

  const mark = Kovetkezo_programok.filter(datum => moment(datum.datum).format('MM') === moment().format('MM')).map(k => {return (moment(k.datum).format("DD-MM-YYYY") )})
  return (
    <>
    <section className="kovetkezo-programok-section" id ="kovetkezo-programo-section" style={{paddingBottom: "85px", minHeight: "calc(100vh - 617px"}}>
      <MDBContainer style={{maxWidth: "1284px"}} className='p-0'>
        <div className="datum-search-div">
          {!isSearchFunction ? <>
          <Calendar  minDetail='month' selectRange={false}
              maxDate={lastDay} 
              minDate={firstDay}
              formatMonthYear={(locale, date) => moment(date).format("MMMM")}
              onChange={(date) => {if(mark.find(x=>x===moment(date).format("DD-MM-YYYY"))){
                setActuelleDate(date); setIsActuelleProgram(true)
               }}}
              tileClassName={({ date, view }) => {
                if(mark.find(x=> x===moment(date).format("DD-MM-YYYY"))){
                  if(moment(date).format("DD-MM-YYYY") === moment(actuelleDate).format("DD-MM-YYYY")){
                    return  'active-highlight'
                  }else {
                    return  'highlight'
                  }
                 
                }
              }}/>
          <button className="datum-search-button" type="button" onClick={() => {setIsSearchFunction(true); setIsActuelleProgram(false); setActuelleDate(moment())}}><img src={searchikon} alt="search ikon"/></button>
          </>:
          <>
          <MDBInput value={searchText}
                          name='searchtext'
                          type="text"
                          onChange={(e) => setSearchText(e.target.value)}
                          label='Program keresése'/>
          <button className="datum-search-button" type="button"  onClick={() => setIsSearchFunction(false)}><img src={calendarikon} alt="search ikon"/></button>
          </>}
        </div>
        {!isActuelleProgram ? <>
        {monthList.map((l,m) => {return (
            <div key={m}>
              <div className="program-title">
                <span>{l +"i programok"}</span>
              </div>
              <MDBRow className="m-0 programok-row">
                {fullItemList.filter(datum => moment(datum.datum).locale('hu').format('MMMM') === l).filter(k => k.title.match(searchText)).map((k,n) => {return (
                  <MDBCol md="4" className="programok-col" key={n}>
                    <ProgramCard msg={k} kovetkezoprogramok={true}/>
                  </MDBCol>
                )})}
            
              </MDBRow>
            </div>
        )})}
        </>: 
        <>
              <div className="program-title">
                <span>{"Keresés: Programok-" + moment(actuelleDate).locale('hu').format('MMMM DD')}</span>
              </div>
              <MDBRow className="m-0 programok-row">
                {fullItemList.filter(k => moment(k.datum).locale('hu').format('YYYY-MM-DD') === moment(actuelleDate).locale('hu').format('YYYY-MM-DD')).map((k,n) => {return (
                  <MDBCol md="4" className="programok-col" key={n}>
                    <ProgramCard msg={k} kovetkezoprogramok={true}/>
                  </MDBCol>
                )})}
              </MDBRow>
              <MDBRow >
                <MDBCol md="4" className="loadmore-div">
                  <button type="button" onClick={() => {setIsActuelleProgram(false); setActuelleDate(moment())}}><span>Vissza</span></button>
                </MDBCol>
              </MDBRow>
        </>}

      </MDBContainer>
    </section>
    </>
  );
};

export default ProgramokListPage;
