import React, { Component } from "react";
import { GoogleMap, LoadScriptNext, MarkerF } from '@react-google-maps/api';
// Source: https://www.npmjs.com/package/@react-google-maps/api

const containerStyle = {
  width: '600px',
  height: '600px',
  borderRadius: "0px",
  border: "none"
};

class GoogleMaps extends Component {

	constructor(props) {
    super(props);
    this.state = {google_maps_geocoder: {}
                  };

    };

    componentDidMount(){
    }

 /* componentDidUpdate(prevProps) {
    if (this.state.google_maps_geocoder?.geocode !== undefined && this.props.isUmkreis) {
      let self = this;
      this.state.google_maps_geocoder.geocode(
          { 'latLng': { lat: this.props.userData.getLatLng().originalLatitude, lng: this.props.userData.getLatLng().originalLongitude} },
          function( results, status ) {
            let addressArray = results.filter((e) => e.types.includes("postal_code"));
            if(addressArray.length > 0){
              self.props.onGetOriginalAdresse(addressArray[0].formatted_address);
            }

          }
      );
    }
  }*/


    onLoad = () => {
      this.setState({google_maps_geocoder :{lat: 47.581010, lng: 18.399630}});
    }

    componentWillUnmount() {
    }


    render() {
      return (
            <>
            <LoadScriptNext googleMapsApiKey="AIzaSyDV1hclNQdPA1iAbsgCKCokf8gBlfiuM0M">
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={{ lat: 47.581009, lng: 18.399630}}
              zoom={15}
              onLoad={this.onLoad}
              options={{
                streetViewControl: false,
                scaleControl: false,
                mapTypeControl: false,
                panControl: false,
                zoomControl: false,
                rotateControl: false,
                fullscreenControl: false
              }}
            >
              <MarkerF position={{ lat: 47.581009, lng:  18.399630 }} />

            </GoogleMap>
            </LoadScriptNext>
          

            </>
      )
    }
  }
export default  GoogleMaps;
