export const FooterDate = [
    
        {
            id: 1,
            elerhetoseg:    {
                                    cim: "2800 Tatabánya, Szent Borbála tér 1.",
                                    telefon: "+36 34 688 100",
                                    email: "info@avertesagoraja.hu"
                            },
                              
        },
        
        {
            id: 2,
            nyitvatartas:    {
                                    hetkozi: "Hétfőtől szombatig: 10:00-20:00",
                                    hetvegi: "Vasárnap: ZÁRVA"
                            },
        },
        {
            id: 3,
            jegypenztarnyitvatartas:    {
                                    hetkozi: "Hétfőtől szombatig: 10:00-19:00",
                                    hetvegi: "Vasárnap: ZÁRVA",
                                    ebedido: "Ebédidő: 13:00-13:45"
                            },
        },
        {
            id: 4,
            licensz: "© 2023 Agora Nonprofit Kft. - Minden jog fenntartva!"
        }
]

