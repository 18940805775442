// REACT IMPORTS
import React, { Component, useRef, useState } from "react";
import { useNavigate, useSearchParams } from 'react-router-dom';
import { GoogleLogin } from '@react-oauth/google';
import { useGoogleLogin } from '@react-oauth/google';
import FacebookLogin from '@greatsumini/react-facebook-login';
import AppleSignin from 'react-apple-signin-auth';
import axios from "axios";
import { backendUrl } from '../../env';
import { FacebookAppId, AppleLogin } from '../../data/loginkeys';

import loginAppleIcon from "../../assets/media/icons/apple-logo.png";
import loginFacebookIcon from "../../assets/media/icons/Facebook social.png";
import loginGoogleIcon from "../../assets/media/icons/google.png";


// MDBOOTSTRAP IMPORTS
import { 
  MDBRow, 
  MDBCol, 
  MDBInputGroup,
  MDBIcon,
  MDBCheckbox
} from 'mdb-react-ui-kit';


// STYLE IMPORTS
import "react-toggle/style.css";


class UjjelszoForm extends Component {
 
  constructor(props) {
    super(props);
      this.state = {email: "" 
      };
    };

    onCustomer = (e) => {
      e.preventDefault();
      var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if(this.state.email.match(validRegex)){
        this.props.newPassword(this.state.email);

        this.setState({
          email: ""
        })
        this.props.navigate('/home')
        
      }else {
        alert('Az Emailforámatum helytelen!')
      }
      
    }

    changeHandler = event => {
      this.setState({ [event.target.name]: event.target.value });
    };

    
  
  render() {
    
    return (<>
              <form onSubmit={(e) => this.onCustomer(e)} className="hirlevel-form" autoComplete="off">
                <div className="white-panel">
                <MDBRow className="pt-4 m-0">
                    <MDBCol md='12' className="p-0">
                      <label htmlFor="firstName">E-mail cím <span>*</span></label><br/>
                      <input
                        id='email'
                        name='email'
                        type='text'
                        onChange={this.changeHandler}
                        value={this.state.email}
                        required
                      />
                    </MDBCol>
                  </MDBRow>

                 
                  <MDBRow className="py-5 m-0">
                    <MDBCol md='12'  className="d-grid align-items-center justify-content-start p-0" >
                          <div className="d-flex align-items-center justify-content-start p-0" >
                            <button type="submit" className="muster-button kiemelt" >
                              <span>E-MAIL KÜLDÉSE</span>
                            </button>
                          </div>
                    </MDBCol>
                  </MDBRow>
                  </div>
              </form>

               
      </>
    );
  }
};

function UjjelszoFormFunc(props) {
  let [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const newPassword = (email, password) => {

    props.context.interAxios.put('/auth/reset-password', {email: email}, function(response) {
    }, function(error) {
      alert("hibás email vagy jelszó");
    });

  };

  return <UjjelszoForm searchParams={searchParams} 
                      navigate={navigate} 
                      context={props.context} 
                      newPassword={newPassword} 
                      />;
}

export default UjjelszoFormFunc;
