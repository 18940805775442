export const MenuList = [
    
        {
            id: 1,
            title: "Programok",
            dropddown_list: [   {
                                    title: "Következő programok",
                                    link: "/kovetkezoprogramok"
                                },
                                {
                                    title: "Állandó programok",
                                    link: "/allandoprogramok"
                                },
                                {
                                    title: "Archívum",
                                    link: "/archivum"
                                }
                            ]
        },
        {
            id: 2,
            title: "Hírek",
            link: "/hirek",
            dropddown_list: []
        },
        {
            id: 3,
            title: "Info",
            dropddown_list: [   {
                                    title: "Kapcsolat",
                                    link: "/kapcsolat"
                                },
                                {
                                    title: "A Vértes Agorája",
                                    link: "/ava"
                                },
                                {
                                    title: "Pályázatok",
                                    link: "/palyazatok"
                                },
                                {
                                    title: "Közösségi szolgálat",
                                    link: "/kozossegiszolgalat"
                                }
                            ]
        },
        {
            id: 4,
            title: "Kortárs Galéria",
            link: "/kortarsgaleria",
            dropddown_list: []
        },
        {
            id: 5,
            title: "Művelődési házak",
            dropddown_list: [   {
                                    title: "József Attila Művelődési Ház",
                                    link: "/jamh"
                                },
                                {
                                    title: "Kertvárosi Bányász Művelődési Otthon",
                                    link: "/kbmo"
                                },
                                {
                                    title: "PályPuskin Művelődési Házzatok",
                                    link: "/pmh"
                                },
                                {
                                    title: "Széchenyi István Művelődési Ház",
                                    link: "/szimh"
                                }
                            ]
        },
        {
            id: 6,
            title: "Média",
            dropddown_list:  [   {
                                    title: "YouTube csatornánk",
                                    link: "/youtube"
                                },
                                {
                                    title: "Galéria",
                                    link: "/galeria"
                                }
                            ]
        }
   
]

export const MenuList_JAMH = [
    
    {
        id: 1,
        title: "Programok",
        dropddown_list: [   {
                                title: "Következő programok",
                                link: "/kovetkezoprogramok"
                            },
                            {
                                title: "Állandó programok",
                                link: "/allandoprogramok"
                            },
                            {
                                title: "Archívum",
                                link: "/archivum"
                            }
                        ]
    },
    {
        id: 2,
        title: "Hírek",
        link: "/hirek",
        dropddown_list: []
    },
    {
        id: 3,
        title: "Info",
        dropddown_list: [   {
                                title: "Kapcsolat",
                                link: "/kapcsolat"
                            },
                            {
                                title: "A Vértes Agorája",
                                link: "/ava"
                            },
                            {
                                title: "Pályázatok",
                                link: "/palyazatok"
                            },
                            {
                                title: "Közösségi szolgálat",
                                link: "/kozossegiszolgalat"
                            }
                        ]
    },
    {
        id: 4,
        title: "Művelődési házak",
        dropddown_list: [   {
                                title: "A Vértes Agorája",
                                link: "/ava"
                            },
                            {
                                title: "Kertvárosi Bányász Művelődési Otthon",
                                link: "/kbmo"
                            },
                            {
                                title: "PályPuskin Művelődési Házzatok",
                                link: "/pmh"
                            },
                            {
                                title: "Széchenyi István Művelődési Ház",
                                link: "/szimh"
                            }
                        ]
    },
    {
        id: 5,
        title: "Média",
        dropddown_list: ["YouTube csatornánk", "Galéria"]
    }

]