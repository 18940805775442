// react imports
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import moment from 'moment'
// CUSTOM COMPONENTS
import SliderComponent from "../components/carousel/SliderComponent"
import ProgramCard from "../components/cards/ProgramCard"
import HirekFooldalCard from "../components/cards/HirekFooldalCard"
import calendarikon from "../assets/media/icons/calendar.png"
import { Kovetkezo_programok, Hirek, Programfuzet, Kiemelt_program } from '../data/TartalmiElemek'

import program_ikon from "../assets/media/icons/program ikon.png"
import bezaras from "../assets/media/icons/bezaras ikon.png"

import { MDBContainer, 
         MDBCol, 
         MDBRow
          } from 'mdb-react-ui-kit';


const HomePage = (props) => {

  const navigate = useNavigate();

  // STATES
  const [fullItemList, setFullItemList] = useState([]);
  const [programfuzet, setProgramfuzet] = useState([]);
  const [fullHirekList, setFullHirekList] = useState([]);
  const [moreProductsAvailable, setMoreProductsAvailable] = useState(true);
  const [actuelleDate, setActuelleDate] = useState();
  const [isActuelleProgram, setIsActuelleProgram] = useState(false);
  const [currentdate, setCurrentdate] = useState(new Date())

  useEffect(() => {
    setTimeout(() => window.scrollTo({
      top: 0,
      behavior: "smooth"
    }), 100);
    setFullItemList(Kovetkezo_programok);
    setFullHirekList(Hirek);
    setProgramfuzet(Programfuzet[0])
    setCurrentdate(new Date());
  }, []);

  const loadMorePrograms = () => {
    navigate('/kovetkezoprogramok')
    setMoreProductsAvailable(true)
  };


  const loadMoreHirek = () => {
    navigate('/hirek')
  };

  
  const firstDay = new Date(currentdate.getFullYear(), currentdate.getMonth(), currentdate.getDate());
  const lastDay = new Date(currentdate.getFullYear(), currentdate.getMonth(), currentdate.getDate() + 6);
  
  const mark = Kovetkezo_programok.filter(datum => moment(datum.datum).format('MM') === moment().format('MM')).map(k => {return (moment(k.datum).format("DD-MM-YYYY") )})
  console.log(isActuelleProgram)
  return (
    <>
    <section className="slide-section" id="slide-section">
      <MDBContainer style={{maxWidth: "1920px"}} className='p-0'>
        <div className="first-div">
          <SliderComponent/>
          <div className="calendar-div">
            <Calendar  minDetail='month' selectRange={false}
                  formatMonthYear={(locale, date) => moment(date).format("MMMM")}
                  maxDate={lastDay} 
                  minDate={firstDay}
                  defaultView={"month"}
                  onChange={(date) => {if(mark.find(x=>x===moment(date).format("DD-MM-YYYY"))){
                    setActuelleDate(date); setIsActuelleProgram(true)
                  }else {
                    setActuelleDate(undefined); setIsActuelleProgram(false)
                  }}}
                  tileClassName={({ date, view }) => {
                    if(mark.find(x=> x===moment(date).format("DD-MM-YYYY"))){
                      if(moment(date).format("DD-MM-YYYY") === moment(actuelleDate).format("DD-MM-YYYY")){
                        return  'active-highlight'
                      }else {
                        return  'highlight'
                      }
                    
                    }
                  }}/>
            <button className="datum-search-button" type="button"><img src={calendarikon} alt="search ikon"/></button>
            {isActuelleProgram && (
            <div className="program-div">
              {Kovetkezo_programok.filter(datum => moment(datum.datum).format("DD-MM-YYYY") === moment(actuelleDate).format("DD-MM-YYYY")).map((k,n) => {return (
                <div key={n}>
                 <div className="active-program-div"/>
                  <div className="active-program-card">
                    <img src={k.image} className="active-program-background-image" al="program-image"/>
                    <div className="ikon-div">
                      <img src={program_ikon} className="program-ikon-image" alt=""/>
                    </div>
                    <div className="bezaras-div" onClick={() => {setActuelleDate(undefined); setIsActuelleProgram(false)}}>
                      <img src={bezaras} className="bezaras-ikon-image" alt="bezaras"/>
                    </div>
                    <div className="text-div">
                      <div className="time">
                        <span className="time-text">{moment(k.datum).format("HH:mm")}</span>
                      </div>
                      <p>{k.title}</p>
                      <span>{k.helyszin}</span>
                    </div>
                  </div>
                </div>
              )})[0]}
            </div>
          )}
          </div>
          
          
        </div>
      </MDBContainer>
    </section>
    <section className="kovetkezo-programok-section" id ="kovetkezo-programo-section">
      <MDBContainer style={{maxWidth: "1284px"}} className='p-0'>
        <div className="program-title">
          <span>Következő programok</span>
        </div>
        <MDBRow className="m-0 programok-row">
          {fullItemList.filter((k,index) => index < 6).map((k,n) => {return (
            <MDBCol md="4" className="programok-col" key={n}>
              <ProgramCard msg={k} kovetkezoprogramok={true}/>
            </MDBCol>
          )})}
          
        </MDBRow>
        {moreProductsAvailable && (
        <div className="loadmore-div">
          <button type="button"  className="muster-button kiemelt" onClick={loadMorePrograms}><span>Továbbiak <svg width="7" height="11" viewBox="0 0 7 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 1L6 7L1 12" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg></span></button>
        </div>)}
      </MDBContainer>
    </section>
    <section className="programfuzet-section" id="programfuzet-section">
      <MDBContainer style={{maxWidth: "1046px"}} className="p-0 programfuzet-div">
          <div className="programfuzet-text">
            <p>{programfuzet.title}</p>
            <span className="description">{programfuzet.description}</span>
            <button type="button" className="muster-button elsodleges"><span>LAPOZZON BELE</span></button>
          </div>
          <img src={programfuzet.image} alt="programfuzet"/>
      </MDBContainer>
    </section>
    <section className="hirek-section" id="hirek-section">
      <MDBContainer style={{maxWidth: "1260px"}} className='p-0 hirek-div'>
        <div className="hirek-title">
          <span>Hírek</span>
        </div>
        <MDBRow className="m-0 hirek-row">
          {fullHirekList.filter((n, index) => index < 3).map((k,n) => {return (
            <MDBCol md="4" className="hirek-col" key={n}>
              <HirekFooldalCard msg={k}/>
            </MDBCol>
          )})}
          
        </MDBRow>
        <div className="loadmore-div">
          <button type="button" className="muster-button kiemelt" onClick={loadMoreHirek}><span>Továbbiak <svg width="7" height="11" viewBox="0 0 7 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 1L6 7L1 12" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg></span></button>
        </div>
      </MDBContainer>
    </section>
    <section className="tbkartya-section" id="tbkartya-section">
      <MDBContainer style={{maxWidth: "1260px"}} className='p-0'>
        <div className="tbkartya-div">
          <div className="info-div">
            <span className="text">Tatabánya Kártya</span>
            <button className="muster-button elsodleges"><span>INFORMÁCIÓ</span></button>
          </div>
          
        </div>
      </MDBContainer>
    </section>
    <section className="hirlevel-section" id="hirlevel-section">
      <div className="hirlevel-div">
        <p>Agora hírlevél</p>
        <span className="hirlevel-text">Iratkozzon fel az Agora hírlevelére és értesüljön elsőként programjainkról!</span>
        <button type="button" className="muster-button elsodleges" onClick={() => navigate('/hirlevelpage')}><span>FELIRATKOZOM</span></button>
      </div>
    </section>
    <section className="video-section" id="video-section">
      <MDBContainer style={{maxWidth: "1260px"}} className='p-0'>
        <div className="video-div">
          <iframe src="https://www.youtube.com/embed/eFQxRd0isAQ?si=_lijzlMdCxgH0Chl&autoplay=0&controls=1&loop=0"  
              height={window.innerWidth/1920 > 1 ? 410 : (window.innerWidth/1920)*410} 
              width={window.innerWidth/1920 > 1 ? 
                      727 : (window.innerWidth/1920)*727} allow="autoplay"></iframe>
          <div className="tartalom-div">
            <p>Valami címe lesz mindig a tartalomnak</p>
            <span>Itt lesz a szöveg, ami leírja, miről szól a videó vagy a kép, ami épp itt van.</span>
          </div>
        </div>
      </MDBContainer>
    </section>
    <section className="agoraapp-section" id="agoraapp-section">
      <MDBContainer style={{maxWidth: "1920px"}} className='p-0 agoraap-container'>
        <div className="agoraapp-div">
            <p>Ismeri a Vértes Agorája alkalmazást?</p>
            <span className="text">Új funkciók és mindig naprakész tartalom az Ön kezében!</span>
            <button className="muster-button elsodleges"><span>ALKALMAZÁS LETÖLTÉSE</span></button>
        </div>
      </MDBContainer>
    </section>
    </>
  );
};

export default HomePage;
