// REACT IMPORTS
import React, { Component, useRef, useState } from "react";
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from "axios";
import { backendUrl } from '../../env';
// MDBOOTSTRAP IMPORTS
import { 
  MDBRow, 
  MDBCol, 
  MDBInputGroup,
  MDBIcon,
  MDBCheckbox
} from 'mdb-react-ui-kit';


// STYLE IMPORTS
import "react-toggle/style.css";


class ForgotpasswordForm extends Component {
 
  constructor(props) {
    super(props);
      this.state = {password: "", confirmPassword: ""
      };
    };

    onCustomer = (e) => {
      e.preventDefault();
      let self = this;

      if (this.state.password === this.state.confirmPassword) {
        axios
          .put(
            backendUrl() + "/users/me/change-password",
            {
              password: this.state.password,
            },
            {
              headers: {Authorization:
                "Bearer " + Object.fromEntries([...this.props.searchParams]).authToken, 'Content-type': 'application/json', 'Accept': 'application/json', 'Platform-Id': '3'}
            },
          )
          .then(function (response) {
            self.props.context.closeLoginModal();
            setTimeout(() => {
              self.props.context.openLoginModal("signin");
            }, 500);
          });
      } else {
        alert("Ellenőrizd a megadott jelszavakat!");
      }
        
        this.setState({
          password: "", confirmPassword: ""
        })
        this.props.setChecked(false)
        
        this.props.navigate('/home')
    }

    changeHandler = event => {
      this.setState({ [event.target.name]: event.target.value });
    };
  
  render() {
    
    return (<>
              <form onSubmit={(e) => this.onCustomer(e)} className="hirlevel-form" autoComplete="off">
                <div className="white-panel">
                 
                  {/** PASSWORDs **/}
                  <MDBRow className="pb-3 m-0">
                    <MDBCol md='12' className="p-0">
                      <label htmlFor="password">Jelszó <span>*</span></label><br/>
                      <MDBInputGroup
                        noWrap 
                        textAfter={(
                          <MDBIcon 
                            className={"fa-regular" + (this.props.hidePassword ? ' fa-eye' : ' fa-eye-slash')}
                            onClick={() => this.props.setHidePassword(!this.props.hidePassword)}
                          ></MDBIcon>
                        )}
                      >
                        <input 
                          type={this.props.hidePassword ? 'password' : 'text'} 
                          value={this.state.password}
                          onChange={this.changeHandler}
                          ref={this.props.passwordRef}
                          id='password'
                          name='password'
                          onKeyDown={(e) => {
                            if (e.key == 'Tab') {
                              e.preventDefault();
                              e.stopPropagation();
                              this.props.passwordtwoRef.current.focus()
                            }
                          }}
                          required
                        />
                      </MDBInputGroup>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow className="pb-3 m-0">
                    <MDBCol md='12' className="p-0">
                      <label htmlFor="password">Jelszó újra <span>*</span></label><br/>
                      <MDBInputGroup
                        noWrap 
                        textAfter={(
                          <MDBIcon 
                            className={"fa-regular" + (this.props.hidePasswordtwo ? ' fa-eye' : ' fa-eye-slash')}
                            onClick={() => this.props.setHidePasswordtwo(!this.props.hidePasswordtwo)}
                          ></MDBIcon>
                        )}
                      >
                        <input 
                          type={this.props.hidePasswordtwo ? 'password' : 'text'} 
                          value={this.state.confirmPassword}
                          onChange={this.changeHandler}
                          ref={this.props.passwordtwoRef}
                          id='confirmPassword'
                          name='confirmPassword'
                          required
                        />
                      </MDBInputGroup>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow className="pt-1 pb-4 m-0">
                    <span className="jelszo-text p-0">A jelszó legalább 6 karakter hosszú legyen és tartalmazzon legalább egy számjegyet, egy kis és egy nagybetűt.</span>
                  </MDBRow>
                 
                  

                  <MDBRow className="pb-3 m-0">
                    <MDBCol md='12'  className="d-grid align-items-center justify-content-start p-0" >
                          <div>
                            <button type="submit" className="muster-button kiemelt" >
                            <span>Jelszó megerősítése</span>
                            </button>
                          </div>
                    </MDBCol>
                  </MDBRow>
           
                
                  </div>
              </form>

               
      </>
    );
  }
};

function ForgotpasswordFormFunc(props) {
  let [searchParams] = useSearchParams();
  const [hidePassword, setHidePassword] = useState(true);
  const [hidePasswordtwo, setHidePasswordtwo] = useState(true);
  const navigate = useNavigate();
  const passwordtwoRef = useRef();
  return <ForgotpasswordForm searchParams={searchParams} 
                      navigate={navigate} 
                      context={props.context} 
                      passwordtwoRef={passwordtwoRef}
                      hidePassword={hidePassword}
                      setHidePassword={setHidePassword}
                      hidePasswordtwo={hidePasswordtwo}
                      setHidePasswordtwo={setHidePasswordtwo}
                      />;
}

export default ForgotpasswordFormFunc;
