// react imports
import React, { useState, useEffect } from "react";
import 'react-calendar/dist/Calendar.css';
import moment from 'moment'
// CUSTOM COMPONENTS
import ProgramCard from "../components/cards/ProgramCard"

import { Archiv_programok } from '../data/TartalmiElemek'


import { MDBContainer, 
         MDBCol, 
         MDBRow
          } from 'mdb-react-ui-kit';


const ArchivProgramokPage = (props) => {

  moment.updateLocale('hu', {
    months : [
        "Január", "Február", "Március", "Április", "Május", "Június", "Július",
        "Augusztus", "Szeptember", "Október", "November", "December"
    ]
  }); 

  // STATES
  const [fullItemList, setFullItemList] = useState([]);
  const [moreProductsAvailable, setMoreProductsAvailable] = useState(true);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
    setFullItemList(Archiv_programok);
  }, []);

  const loadMorePrograms = () => {
    var arrayB= Archiv_programok
    var newArray = fullItemList.concat(arrayB);
    setFullItemList(newArray)
    setMoreProductsAvailable(true)
  };

  return (
    <>
    <section className="kovetkezo-programok-section" id ="kovetkezo-programo-section" style={{paddingBottom: "85px", minHeight: "calc(100vh - 617px"}}>
      <MDBContainer style={{maxWidth: "1284px"}} className='p-0'>
        
            <div>
              <div className="program-title">
                <span>Archívum</span>
              </div>
              <MDBRow className="m-0 programok-row">
                {fullItemList.map((k,n) => {return (
                  <MDBCol md="4" className="programok-col" key={n}>
                    <ProgramCard msg={k}/>
                  </MDBCol>
                )})}
              </MDBRow>
              {moreProductsAvailable && (
              <div className="loadmore-div">
                <button type="button" onClick={loadMorePrograms}><span>Továbbiak</span></button>
              </div>)}
            </div>
      </MDBContainer>
    </section>
    </>
  );
};

export default ArchivProgramokPage;
