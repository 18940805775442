import React, { useContext, useState  } from "react";
import { useNavigate } from 'react-router-dom';

import AppContext from '../../AppContext';
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBRow,
  MDBCol,
  MDBCheckbox
} from 'mdb-react-ui-kit';


const SocialRegistrationModal = (props) => {

const context = useContext(AppContext);

const [checked, setChecked] = useState(false);
const [checked_2, setChecked_2] = useState(false);

// router hooks
const navigate = useNavigate();

    const onCustomer = (e) => {
      e.preventDefault();
      props.hideModal()
      /*context.interAxios.post('/auth/social/facebook/signup', {accessToken: props.accessToken}, function(response) {
        context.onSignIn(response.data);
        props.hideModal()
      });*/
      
    }


    return (
    <MDBModal staticBackdrop tabIndex='-1' closeOnEsc={false} show={props.visible}  className="altalanos-modal">
      <MDBModalDialog scrollable centered>
        <MDBModalContent>
          <MDBModalBody>
          <MDBModalTitle>
              <span className="modal-title">A regisztrációhoz szükséges a következőket elfogadni:</span>
          </MDBModalTitle>
          <form onSubmit={(e) => onCustomer(e)} className="hirlevel-form" autoComplete="off">
            <MDBRow className="pt-2 pb-4 m-0">
                  <MDBCol md='12'  className="d-grid align-items-center justify-content-start p-0">
                    <div className="checkbox-div d-flex align-items-start pb-1">
                      <MDBCheckbox
                        id='controlledCheckbox_1'
                        label=""
                        checked={checked}
                        onChange={() => setChecked(!checked)}
                        required
                      /> 
                      <span className="modal-description">Elolvastam és elfogadom az 
                        <a href={'/aszf'} target="_blank" rel="noopener noreferrer"> adatvédelmi tájékoztatót</a>.<span className="stern">*</span></span>
                      
                    </div>   
                  </MDBCol>
                  <MDBCol md='12'  className="d-grid align-items-center justify-content-start px-0 pt-2">
                    <div className="checkbox-div d-flex align-items-start pb-1">
                      <MDBCheckbox
                        id='controlledCheckbox_2'
                        label=""
                        checked={checked_2}
                        onChange={() => setChecked_2(!checked_2)}
                        required
                      /> 
                      <span className="modal-description">Hozzájárulok, hogy az általam megadott szeményes adatokat az Adatkezelő az Adatkezelési tájékoztatóban meghatározott célból kezelje.<span className="stern">*</span></span>
                      
                    </div>   
                  </MDBCol>
                </MDBRow>
              <div className="justify-content-start d-flex align-ites-center pt-3" >
                <button type="submit" className="muster-button kiemelt" >
                  <span>TOVÁBB</span>
                </button>
              </div>
            </form>
          </MDBModalBody>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  );
}
export default SocialRegistrationModal;
