// react imports
import React, { useContext, useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import AppContext from '../../AppContext';
// IMPORT LAYOUT COMPONENTS
import Footer from "./footer/Footer";
import Header from "./header/Header";

import SocialRegistrationModal from "../modals/SocialRegistrationModal";

function Layout({ children }) {
  
  const context = useContext(AppContext);
  
  return (
    <>
      
      <Header />
      <main>
      <Outlet/>
      </main>
      <Footer />

      {context.getState()?.modalName === "signup" && (
        <SocialRegistrationModal
        visible={context.getState()?.loginModalOpened}
        hideModal={context.closeLoginModal}
        openLoginModal={context.openLoginModal}
        accessToken={context.getState()?.accessToken}
        authorizationCode={context.getState()?.authorizationCode}
        socialType={context.getState()?.socialType}
        context={context}
      />
      )}
     
      
    </>
  );
}

export default Layout;
