// react imports
import React, { useEffect } from "react";

import { Vertes_agoraja } from '../data/TartalmiElemek'


import { MDBContainer
          } from 'mdb-react-ui-kit';


const AVertesAgorajaPage = (props) => {

  
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }, []);


  return (
    <>
    <section className="vertesagoraja-section" id ="vertesagoraja-section" style={{ minHeight: "calc(100vh - 617px)"}}>
      <MDBContainer style={{maxWidth: "1920px"}} className='p-0'>
        <div className="vertesagoraja-div">
          {Vertes_agoraja}
        </div>
      </MDBContainer>
    </section>
    </>
  );
};

export default AVertesAgorajaPage;
