// REACT IMPORTS
import React, { Component, useRef, useState } from "react";
import { useNavigate, useSearchParams } from 'react-router-dom';
import { GoogleLogin } from '@react-oauth/google';
import { useGoogleLogin } from '@react-oauth/google';
import FacebookLogin from '@greatsumini/react-facebook-login';
import AppleSignin from 'react-apple-signin-auth';
import axios from "axios";
import { backendUrl } from '../../env';
import { FacebookAppId, AppleLogin } from '../../data/loginkeys';

import loginAppleIcon from "../../assets/media/icons/apple-logo.png";
import loginFacebookIcon from "../../assets/media/icons/Facebook social.png";
import loginGoogleIcon from "../../assets/media/icons/google.png";


// MDBOOTSTRAP IMPORTS
import { 
  MDBRow, 
  MDBCol, 
  MDBInputGroup,
  MDBIcon,
  MDBCheckbox
} from 'mdb-react-ui-kit';


// STYLE IMPORTS
import "react-toggle/style.css";


class BelepesForm extends Component {
 
  constructor(props) {
    super(props);
      this.state = {email: "",
                    name: "",  
      };
    };

    onCustomer = (e) => {
      e.preventDefault();
      var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if(this.state.email.match(validRegex)){
        //let self = this;
        this.props.loginEvent(this.state.email, this.state.password);

        this.setState({
          email: "",
          name: "", 
        })
        this.props.setChecked_1(false)
        this.props.navigate('/home')
        
      }else {
        alert('Az Emailforámatum helytelen!')
      }
      
    }

    changeHandler = event => {
      this.setState({ [event.target.name]: event.target.value });
    };

    
  
  render() {
    
    return (<>
              <form onSubmit={(e) => this.onCustomer(e)} className="hirlevel-form" autoComplete="off">
                <div className="white-panel">
                <MDBRow className="pb-3 m-0">
                    <MDBCol md='12' className="p-0">
                      <label htmlFor="firstName">E-mail cím <span>*</span></label><br/>
                      <input
                        id='email'
                        name='email'
                        type='text'
                        onChange={this.changeHandler}
                        onKeyDown={(e) => {
                          if (e.key == 'Tab') {
                            e.preventDefault();
                            e.stopPropagation();
                            this.props.passwordRef.current.focus()
                          }
                        }}
                        value={this.state.email}
                        required
                      />
                    </MDBCol>
                  </MDBRow>

                  {/** PASSWORDs **/}
                  <MDBRow className="pb-3 m-0">
                    <MDBCol md='12' className="p-0">
                      <label htmlFor="password">Jelszó <span>*</span></label><br/>
                      <MDBInputGroup
                        noWrap 
                        textAfter={(
                          <MDBIcon 
                            className={"fa-regular" + (this.props.hidePassword ? ' fa-eye' : ' fa-eye-slash')}
                            onClick={() => this.props.setHidePassword(!this.props.hidePassword)}
                          ></MDBIcon>
                        )}
                      >
                        <input 
                          type={this.props.hidePassword ? 'password' : 'text'} 
                          value={this.state.password}
                          onChange={this.changeHandler}
                          ref={this.props.passwordRef}
                          id='password'
                          name='password'
                          required
                        />
                      </MDBInputGroup>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow className="pt-3 pb-4 m-0">
                    <MDBCol md='6'  className="d-grid align-items-center justify-content-start p-0">
                      <div className="checkbox-div d-flex align-items-start">
                      <MDBCheckbox
                          id='controlledCheckbox_1'
                          label=""
                          checked={this.props.checked_2}
                          onChange={() => this.props.setChecked_1(!this.props.checked_1)}
                        /> 
                        <span><span>Jelszó megjegyzése</span></span>
                        
                      </div>   
                    </MDBCol>
                    <MDBCol md='6'  className="d-grid align-items-center justify-content-end p-0">
                      <button type="button" className="datum-search-button" 
                            onClick={() => {this.props.navigate('/ujjelszo')}}>
                        <span>Elfelejtett jelszó</span>
                      </button>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow className="pb-3 m-0">
                    <MDBCol md='12'  className="d-grid align-items-center justify-content-start p-0" >
                          <div className="d-flex align-items-center justify-content-start p-0" >
                            <button type="submit" className="muster-button kiemelt" >
                              <span>BEJELENTKEZÉS</span>
                            </button>
                            <button type="button" className="muster-button transparent" onClick={() => this.props.navigate('/regisztracio')}>
                              <span>REGISZTRÁCIÓ</span>
                            </button>
                          </div>
                    </MDBCol>
                  </MDBRow>

                  <div className="underline"/>

                  
           
                
                  </div>
              </form>
              <MDBRow className="pb-3 m-0">
                    <MDBCol  md='6'  className="d-grid align-items-center justify-content-start p-0">
                      <FacebookLogin
                        appId= {FacebookAppId.appId}
                        style={{
                          backgroundColor: '#4A9EEB',
                          color: '#fff',
                          textAlign: 'center', 
                          fontSize: '14px',
                          fontStyle: 'normal',
                          fontWeight: 800,
                          lineHeight: '25px', 
                          padding: "10px 20px 10px 24px",
                          border: "none",
                          borderRadius: "0",
                        }}
                        children={<div className="jc-btn white"><img src={loginFacebookIcon} alt="Facebook Icon" height='25' className="me-2"/>
                        FOLYTATÁS FACEBOOK FIÓKKAL</div>} 
                        onSuccess={(response) => {
                          
                          let self = this;
                          this.props.context.interAxios.post('/auth/social/facebook/signin', {accessToken: response.accessToken}, function(response) {
                            self.props.context.onSignIn(response.data);
                          }, function(error) {
                            if(error.data.error.code === 20013){
                              self.props.context.openSocialLoginModal("signup", response.accessToken, "", "facebook")
                            }
                          });
                        }}
                        onFail={(error) => {
                            console.log('Login Failed!', error);
                        }}
                        onProfileSuccess={(response) => {
                            console.log(response);
                        }}
                      />
                    </MDBCol>
                    <MDBCol  md='6'  className="d-grid align-items-center justify-content-end p-0">
                    <button className="social-google" onClick={() => {{/*this.props.googleLogin()*/}; this.props.context.openSocialLoginModal("signup")}}>
                      <img src={loginGoogleIcon} alt="Google Icon" height='25'/>
                      <span>BEJELENTKEZÉS GOOGLE FIÓKKAL</span>
                    </button> 
                    </MDBCol>
                  </MDBRow>

                  <MDBRow className="pb-3 pt-3 m-0">
                    <MDBCol md='12'  className="d-grid align-items-center justify-content-center p-0">
                    <AppleSignin
                      authOptions={{
                        clientId: AppleLogin.clientId,
                        scope: AppleLogin.scope,
                        redirectURI: AppleLogin.redirectURI,
                        state: AppleLogin.state,
                        nonce: AppleLogin.nonce,
                        usePopup: AppleLogin.usePopup,
                      }}
                      uiType="dark"
                      className="apple-auth-btn"
                      noDefaultStyle={false}
                      buttonExtraChildren="Continue with Apple"
                      onSuccess={(response) => {
                        let self = this;
                        axios.post(backendUrl() +'/auth/social/apple/signin', { 'accessToken': response.authorization.id_token, 'authorizationCode': response.authorization.code }, { headers: { 'Content-type': 'application/json', 'Accept': 'application/json', 'Platform-Id': '3'}}).then(function(response) {
                          self.props.context.onSignIn(response.data);
                        }, function(error) {
                          if(error.data.error.code === 20013){
                            self.props.context.openSocialLoginModal("signup", response.authorization.id_token, response.authorization.code, "apple")
                          }
                        })
                      }}
                      onError={(error) => console.error(error)}
                      skipScript={false}
                      render={(props) => <button className="social-apple" {...props}> <img src={loginAppleIcon} alt="Apple Icon" height='25'/>
                      <span>SIGN IN WITH APPLE</span></button>}
                    />
                    </MDBCol>
                  </MDBRow>
               
      </>
    );
  }
};

function BelepesFormFunc(props) {
  let [searchParams] = useSearchParams();
  const [checked_1, setChecked_1] = useState(false);
  const [hidePassword, setHidePassword] = useState(true);
  const navigate = useNavigate();
  const passwordRef = useRef();
  
  const googleLogin = useGoogleLogin({
    onSuccess: async tokenResponse => {
      props.context.interAxios.post('/auth/social/google/signin', {token: tokenResponse.access_token}, function(response) {
        props.context.onSignIn(response.data);
      }, function(error) {
        alert("TODO");
        props.context.openSocialLoginModal("signup")
      });
    },
    onError: (error) => alert('Login Failed:', error)
  });

  const loginEvent = (email, password) => {

    props.context.interAxios.post('/auth/login', {password: password, email: email}, function(response) {
      props.context.onSignIn(response.data)
    }, function(error) {
      alert("hibás email vagy jelszó");
    });

  };

  return <BelepesForm searchParams={searchParams} 
                      navigate={navigate} 
                      context={props.context} 
                      emailRef={passwordRef}
                      checked_1={checked_1}
                      setChecked_1={setChecked_1}
                      hidePassword={hidePassword}
                      setHidePassword={setHidePassword}
                      loginEvent={loginEvent} 
                      googleLogin={googleLogin}
                      />;
}

export default BelepesFormFunc;
